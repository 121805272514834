import { END_POINT_MasterData } from "../../../Auth/Api/Endpoint";
import { Service } from "../../../common/axiosSetup";
export class LoadDashBoardServiceReport{
    Service:Service;
    constructor(){
        this.Service = new Service();
    }
    async LoadDashBoard(LoadDashBoardServiceEntityObj:any){
        return await this.Service.post(END_POINT_MasterData,"Common/LoadDashBoardData",LoadDashBoardServiceEntityObj)
    }
}
import { Component } from "react";
import "./../../../Css/Login.css";
import Logo from "./../../../Assets/Images/logo_skubiq.svg";
import { observer } from "mobx-react";
import { LoginService } from "./loginService.service";
import StickyText from "../../../common/StickyText/StickyText";
import { LoginStoreCreation } from "./LoginStore.store";
import { SpinnerLogin } from "../../../common/Spinner/SpinnerLogin";
import LoginCaptch from "./LoginCaptch";
import React from "react";
//import { NavLink } from "react-router-dom";


interface IForgetPassword {
    Email: string,
    sticky_show: boolean,
    sticky_type: string,
    sticky_title: string,
    sticky_message: string,
}
@observer
class ForgotPassword extends Component<any, IForgetPassword> {
    child: any;
    ForgetPasswordEntityObject: any = [];
    LoginServiceObj: LoginService;
    constructor(props: any) {
        super(props);
        this.LoginServiceObj = new LoginService();
        this.child = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            Email: '',
            sticky_show: false,
            sticky_type: '',
            sticky_title: '',
            sticky_message: '',
        };
    }
    stickyText(show: boolean, type: string, title: string, message: string) {
        this.setState({
            sticky_show: show,
            sticky_type: type,
            sticky_title: title,
            sticky_message: message
        })
        return false;
    }
    getDatafromChild(val: boolean) {
        this.setState({ sticky_show: val })
    }
    
    resetPassword = async () => {
        //debugger
        if (this.state.Email === "") {
            this.stickyText(true, 'error', 'error', 'Please enter email');
            return false
        }
        // let val = this.child.current.capchavalidation()
        // if (val === false) {
        //     this.stickyText(true, 'error', 'Error', 'Please enter valid captcha');
        // }--- commented as per QA Reqrmnt
        else {
            if (this.state.Email !== "") {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!re.test(this.state.Email)) {
                    this.stickyText(true, "error", "Error", "Please enter valid company email");
                    return false;
                }
            }
        }
        this.ForgetPasswordEntityObject =
        {
            "Email": this.state.Email,
        };
        ////console.log(this.ForgetPasswordEntityObject)
        const res: any = await this.LoginServiceObj.ForgetPasswordService(this.ForgetPasswordEntityObject);
        //debugger
        ////console.log(res)
        if (res.status === 200) {
            var GetData = res.data.entityObject.result;
            if (GetData === "Success") {
                this.stickyText(true, 'success', 'sucess', 'Mail successfully sent');
                setTimeout(() => {
                    this.setState({ sticky_show: false })
                    window.location.href = "/";
                }, 1500)
            }

            else if (GetData === "Fail") {
                this.stickyText(true, 'error', 'error', 'Failed');
                setTimeout(() => {
                    this.setState({ sticky_show: false })
                }, 3000)
            }
            else if (GetData === "Invalid Email ID") {
                this.stickyText(true, 'error', 'error', 'Enter Valid Email ID');
                setTimeout(() => {
                    this.setState({ sticky_show: false })
                }, 3000)
            }
        }
    }
    LoginAgain () {
        LoginStoreCreation.ForgetPasswordStore(false)
    }
    public handleChange = (e: any) => {
        //debugger
        if (e.target.name === "Email") {
            const re = /^[A-Za-z0-9-!@#$%*?.]+$/;
            if (e.target.value === "" || (re.test(e.target.value))) {
                this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false });
            }
        }
        //this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show:false })
    }

    render() {  
        //debugger
        return (
            <div className="ForgetPassword">
                <SpinnerLogin />
                <>
                    {
                        this.state.sticky_show ?
                            <StickyText
                                display={this.state.sticky_show}
                                sticky_type={this.state.sticky_type}
                                sticky_title={this.state.sticky_title}
                                sticky_message={this.state.sticky_message}
                                sendData={this.getDatafromChild}
                                key={this.state.sticky_title}
                            />
                            :
                            <></>
                    }
                </>
                <div className="ForgetPassword_Body">
                    <div className="Image_title">
                        <img src={Logo} height="60px" alt="logo" />
                    </div>
                    <h1>Forget your password</h1>
                    <div className="Login_Form">
                        <div className="divFlex">
                            <input required
                                type="text"
                                className="Email"
                                autoComplete="off"
                                name="Email"
                                value={this.state.Email}
                                onChange={this.handleChange}
                            />
                            <label>Email</label>
                        </div>
                        <br/><br/>
                        {/* <LoginCaptch ref={this.child} /> */}
                        <div className="sign_in_out_buttons">
                            <button className="Sign_in" onClick={this.resetPassword}>Reset Password</button>
                        </div>
                        <div className="remember_forget">
                            <span style={{ cursor: "pointer" }} onClick={()=> this.LoginAgain()} >Back to login</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;

import { END_POINT_SSO, END_POINT_SKUBIQ_SECURITY ,END_POINT_MasterData} from "../../../Auth/Api/Endpoint";
import { Service } from "../../../common/axiosSetup";
import { PasswordChangeEntityObject } from './LoginUtils'
import { QueryString } from './CheckAuthorization'
export class LoginService {
    Service: Service;
    constructor() {
        this.Service = new Service();
    }
    async getloginService (LoginEntityObject:any) {
        return await this.Service.login(END_POINT_SSO,'connect/token',LoginEntityObject)
        // return await this.Service.login('http://192.168.1.145:33144/','connect/token',LoginEntityObject)
    }
    async getUserInfoService (LoadUserInfoEntityObj:any) {
        return await this.Service.post(END_POINT_SKUBIQ_SECURITY,'api/Security/GetUsereInfoByUserId',LoadUserInfoEntityObj)
    }
    async getLanuagesService (LangEntityObject:any) {
        return await this.Service.post(END_POINT_MasterData,'Common/Getlanguages',LangEntityObject)
    }
    async LoadGenericService(LoadGenericMenuServiceEntityObj:any){
        return await this.Service.post(END_POINT_SKUBIQ_SECURITY,"api/Security/GetMenusByUserId",LoadGenericMenuServiceEntityObj)
    }
    async LoadGenericByLangService(langugeEntity:any){
        return await this.Service.post(END_POINT_MasterData,"Common/GetMenuDataByLanguageCode",langugeEntity)
    }
    async GetSubscriptionAlertService(GetSubscriptionAlert:any){
        return await this.Service.profileupdate(END_POINT_SSO,"api/Account/AccountAdminDisplayMSG",GetSubscriptionAlert)
    }
    async ForgetPasswordService(ForgetPasswordEntityObject: any) {
        //debugger
        return await this.Service.forget_change_Password(END_POINT_MasterData, 'Common/ForgetPassword', ForgetPasswordEntityObject)
    }
    async ChangePasswordService(ChangePasswordEntityObject: any) {
        //debugger
        return await this.Service.forget_change_Password(END_POINT_MasterData, 'Common/ChangePassword', ChangePasswordEntityObject)
    }
    async ProfileChangePasswordService(PasswordChangeEntityObject: PasswordChangeEntityObject) {
        //debugger
        // return await this.Service.profileupdate('http://192.168.1.167:4891/', 'api/Security/Profileupdation', PasswordChangeEntityObject)
        return await this.Service.profileupdate(END_POINT_SKUBIQ_SECURITY, 'api/Security/Profileupdation', PasswordChangeEntityObject)
    }
    async CheckAuthorizationService(QueryString: QueryString) {
        //debugger
        return await this.Service.post(END_POINT_MasterData, 'Common/CheckAuthorization', QueryString)
    }
    async LogInOutService(QueryString: QueryString) {
        //debugger
        return await this.Service.post(END_POINT_MasterData, 'Account/InsertLogAudit', QueryString)
    }
}
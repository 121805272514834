import { Drawer } from "antd";
import { PureComponent } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import ExportPDF from "./ExportPDF";

interface Istate {
  showModel: boolean;
}
export default class HelpDesk extends PureComponent<any,Istate> {
  constructor(e: Istate) {
    super(e);
    this.state = {
      showModel: false,
    };
  }

  handleCancel = () => {
    this.setState({ showModel: false});
  };
  onHelpDesk = () => {
    //debugger;
    this.setState({ showModel: true });
  };
  componentDidMount=()=>{
    var pp=window.location.pathname.split('/');
    // var UrlArray = pp.split('/');
    // (isNaN(parseInt(pp))=== true ? "" : pp.split(pp,1));
    // if(isNaN(parseInt(pp))===true)
    ////debugger
    // window.alert(window.location.pathname.slice(1,-1))
    for(let i=0 ; i<pp.length;++i){
        // alert(pp)
    }
    // {
    //   UrlArray.splice(-1,1)
    //   for(let i=0;i<UrlArray.length;++i){
    //    var aa=[] ;
    //    aa.push("/",UrlArray);
    //   }
    // }
    // alert(aa)
    // alert(UrlArray)
  }

  render() {
   // //debugger;
    return (
      <div>
      <InfoCircleOutlined onClick={() => this.onHelpDesk()} />
      <Drawer
      style={{/*position:'fixed'*/}}
        visible={this.state.showModel}
        onClose={this.handleCancel}
      >
        {/* /////// Default ////////// */}

        <div
          style={{
            display: `${
              window.location.pathname === "/Default" ? "block"
              : "none"
            }`,
          }}
        >
          <ExportPDF menupath='PDF0'/>
        </div>

        <div
          style={{
            display: `${
              window.location.pathname ===  "/"   ? "block"
              : "none"
            }`,
          }}
        >
          <ExportPDF menupath='PDF0'/>
        </div>

        {/* /////// InwardOrder ////////// */}
        <div
          style={{
            display: `${
              window.location.pathname === "/Orders/POList"
                ? "block"
                : "none"
            }`,
          }}
        >
          <ExportPDF menupath='PDF1'/>
        </div>

        {/* ///////OutwardOrder ////////// */}

        <div
          style={{
            display: `${
              window.location.pathname === "/Orders/SOList"
                ? "block"
                : "none"
            }`,
          }}
        >
           <ExportPDF menupath='PDF3'/>
        </div>

        {/* /////// Orders/Returns/SupplierReturns ////////// */}
        <div
          style={{
            display: `${
              window.location.pathname === "/Orders/Returns/SupplierReturns"
                ? "block"
                : "none"
            }`,
          }}
        >
          <ExportPDF menupath='PDF4'/>
        </div>

        {/* /////// Orders/HouseKeeping/FastMovingLocsTransfer ////////// */}
        <div
          style={{
            display: `${
              window.location.pathname === "/Orders/HouseKeeping/FastMovingLocsTransfer"
                ? "block"
                : "none"
            }`,
          }}
        >
          <ExportPDF menupath='PDF2'/>
          <h1>Supplier List</h1>
          </div>

        {/* /////// Orders/HouseKeeping/CycleCountList ////////// */}
        <div
          style={{
            display: `${
              window.location.pathname === "/Orders/HouseKeeping/CycleCountList"
                ? "block"
                : "none"
            }`,
          }}
        >
           <ExportPDF menupath='PDF5'/>
          
        </div>
        <div>
           <ExportPDF menupath=''/>  
        </div>

      </Drawer>
    </div>
    );
  }
}

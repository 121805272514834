import React, { Fragment, Suspense, lazy } from "react";
import "./App.css";
import { Route, Switch } from "react-router";
import Drawer from "./../src/Modules/General/Drawer/Drawer";
import { observer } from "mobx-react";
import { Layout } from "antd";
import { RouterData } from "./common/RouterData.store";
import Login from "./Modules/General/Login/Login";
import ForgetPassword from "./Modules/General/Login/ForgetPassword";
import { LoginStoreCreation } from "./Modules/General/Login/LoginStore.store";
import "./Modules/General/Login/Login";
import "./Modules/General/Dashboard/Dashboard";
import ChangePassword from "./Modules/General/Login/ChangePassword";
import { Link } from "react-router-dom";
import { Spinner } from "./common/Spinner/Spinner";
import ErrorModal from "./common/ErrorModal/ErrorModal";
import favicon from "./Assets/Images/FalconWMS_logo.png";
import favicondark from "./Assets/Images/FalconWMS_logo.png";
import AppHeader from './AppHead';
import ErrorModalURL from "./common/ErrorModal/ErrorModalURL";
import i18next from "i18next";
import { reactLocalStorage } from "reactjs-localstorage";
import './RtL.css';

export interface IRouterData {
  id: string;
  path: string;
  fileLocation: string;
  name: string;
  isActive: boolean;
  params?: any;
  breadcrumbPath?: string;
}

interface IProps { }

interface IState {
  router: IRouterData[];
  Storage: any;
  username: string;
  password: string;
  flag: boolean;
  ChangePassword: boolean;
  collapsed: boolean;
  dark: boolean;
  Year:any
}
const { Header, Footer } = Layout;

@observer
export class ApComponent extends React.Component<any, IState, IProps> {
  LoginEntityObject: any = [];
  LoginData: any;
  ForgetPasswordFlag: boolean;
  constructor(props: any) {
    super(props);
    const { ForgetPasswordFlag } = LoginStoreCreation;
    this.ForgetPasswordFlag = ForgetPasswordFlag;
    this.state = {
      collapsed: false,
      router: [],
      username: "",
      password: "",
      Storage: [],
      flag: true,
      ChangePassword: false,
      dark: false,
      Year: new Date().getFullYear()
    };
  }
  componentDidMount = async () => {
    var URL = window.location.pathname;
    if (URL === "/login") {
      //reactLocalStorage.clear();
    }
    const lang: any = reactLocalStorage.get('lang') === undefined || reactLocalStorage.get('lang') === null || reactLocalStorage.get('lang') === "" ? 'enus' : reactLocalStorage.get('lang');
    
    if (lang === 'ar') {
      var element = document.getElementById("RtL");
      element?.classList.toggle("rtl");
    }
    
    i18next.changeLanguage(lang)

    let color_ = localStorage.getItem("color");
    let dark: any = localStorage.getItem("dark");

    if (color_ === "") {
      document.documentElement.style.setProperty("--basic", color_);
      document.documentElement.style.setProperty("--li-active", color_);
    } else {
      document.documentElement.style.setProperty("--basic", color_);
      document.documentElement.style.setProperty("--li-active", color_);
    }

    if (dark === true) {
      document.documentElement.setAttribute("data-theme", dark);
    }
    else {
      document.documentElement.setAttribute("data-theme", dark);
    }
    if (this.ForgetPasswordFlag === false) {
      LoginStoreCreation.LoadLoginStoreFromLocalStorage();
    }
    if (URL === "/changepassword") {
      this.setState({ ChangePassword: true });
    }
  };


  render() {
    const { router } = RouterData;
    const { jwtToken, ForgetPasswordFlag, errorURL, DarkMode } = LoginStoreCreation;
    if (jwtToken === "" || jwtToken === "Invalid" || jwtToken === undefined || jwtToken === null) {
      if (this.state.ChangePassword === true) {
        return <div>{<ChangePassword />}</div>;
      } else if (ForgetPasswordFlag === false) {
        return <div>{<Login />}</div>;
      } else if (ForgetPasswordFlag === true) {
        return <div>{<ForgetPassword />}</div>;
      }
    }
    else if (window.location.pathname === "/login") {
      window.location.href = "/";
    }
    else {
      return (
        <>
          <ErrorModal />
          {errorURL === true ? <ErrorModalURL /> : ""}
          <div style={{ display: "flex" }}>
            <div className="Drawer_Style">
              <Drawer />
            </div>
            <Switch>
              <Fragment>
                <Layout className="site-layout">
                  <Header
                    className="site-layout-background profile_btn"
                    style={{ padding: 0 }}
                  >
                    <Suspense fallback={
                      <div className="site-layout-background layout_height">
                        
                      </div>
                    }
                    >
                      <AppHeader />
                    </Suspense>
                  </Header>
                  <Suspense
                    fallback={
                      <div className="site-layout-background layout_height">
                        loading...
                      </div>
                    }
                  >
                    <Spinner />
                    {router.length &&
                      router.map((item: IRouterData, index: number) => {
                        return (
                          item.isActive && (
                            <Fragment key={index + 1}>
                              <Route
                                path={
                                  item.params?.length
                                    ? `${item.path}${item.params}`
                                    : item.path
                                }
                                component={lazy(
                                  () => import(`${item.fileLocation}`)
                                )}
                                exact
                              />
                            </Fragment>
                          )
                        );
                      })}
                  </Suspense>
                  <Footer
                    className="Footer"
                    style={{ textAlign: "center", padding: "0px" }}
                  >
                    {DarkMode ? (
                      <img
                        className="FooterICon"
                        src={favicondark}
                        alt="Footer ICON"
                      />
                    ) : (
                      <img
                        className="FooterICon"
                        src={favicon}
                        alt="Footer ICON"
                      />
                    )}{" "}
                    © {this.state.Year} Powered by{" "}
                    <Link
                      to="https://inventrax.com/"
                      rel="noopener"
                      target="_blank"
                    >
                      Inventrax
                    </Link>
                  </Footer>
                </Layout>
              </Fragment>
            </Switch>
          </div>
        </>
      );
    }
  }
}

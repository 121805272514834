import React from "react";
import { Button, Empty, Layout, Table } from "antd";
import { Row, Col, Card } from "antd";
import "antd/dist/antd.css";
import { LoadDashBoardStoreCreation } from "../Dashboard/DashboardStore.store";
import {// ResponsiveContainer,
  // XAxis,
  // YAxis,
  // CartesianGrid,
  // Legend,
  Tooltip,
  // AreaChart,
  // Area,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import moment from 'moment';
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import Autocomplete from "../../../common/AutoComplete/MerlinAutoComplete";
import { DashBoardAutoCompleteService } from "../../../common/Services/DashBoardAutoComplete/DashBoardAutoCompleteService";
import { Service } from "../../../common/axiosSetup";
import { IKeyValueData } from "../../mInventory/FastMovingLocsTransfer/FastMovingLocsTransferUtils";
import {
  DashBoardEntityObject,
  LoadDashBoardServiceEntityObj,
} from "./DashboardUtils";
import { LoginStoreCreation } from "../Login/LoginStore.store";
import { UserInfoEntity } from "../Login/LoginUtils";
import inbound from "./../../../Assets/Images/inbound.svg";
import outbound from "./../../../Assets/Images/outbound.svg";
import inward from "./../../../Assets/Images/inward_process.svg";
import outward from "./../../../Assets/Images/outward_process.svg";
import active_sku from "./../../../Assets/Images/active_sku.svg";
import customer_returns from "./../../../Assets/Images/customer_returns.svg";
import so_cancel from "./../../../Assets/Images/so_cancel.svg";
import supplier_returns from "./../../../Assets/Images/supplier_returns.svg";
import HourlyDashBoard from "./HourlyDashBoard";
import { withTranslation } from 'react-i18next';

const { Content } = Layout;
interface ITariffCreation {
  dropdownData: any;
  WarehouseID: number;
  WHCode: string;
  warehouse: string;
  activeIndex: number;
  StockactiveIndex: number;
  Date: any;
}
@observer
class Dashboard extends React.Component<any, ITariffCreation> {
  Service: Service;
  DropdownServiceobj: DashBoardAutoCompleteService;
  UserInfo: UserInfoEntity;
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.DropdownServiceobj = new DashBoardAutoCompleteService();
    const { jwtToken } = LoginStoreCreation;
    this.UserInfo = JSON.parse(jwtToken);
    this.state = {
      dropdownData: [],
      WarehouseID: 0,
      WHCode: "",
      warehouse: "",
      activeIndex: 0,
      StockactiveIndex: 0,
      Date: new Date().toJSON().slice(0, 7)
    };
  }
  DashBoardAutoComplete = async (prefix: string) => {
    if (prefix === "") {
      this.setState({ dropdownData: [] });
    }
    let DashBoardEntityObject: DashBoardEntityObject = {
      AccountID: this.UserInfo.AccountID,
      prefix: "",
    };
    const res: any = await this.DropdownServiceobj.DashBoardService(
      DashBoardEntityObject
    );
    var getData = JSON.parse(res.data.entityObject.result);
    var tempData = [];
    if (res.status === 200) {
      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].WarehouseID,
          value: getData[i].warehouse,
        });
      }
    }
    this.setState({ dropdownData: tempData });
  };
  onSelectDashBOard = (data: IKeyValueData[]) => {
    this.setState({ WarehouseID: data[0].key, warehouse: data[0].value });
  };
  public handleChange = (e: any) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
  }
  GetDashBoard_on_Search() {
    let LoadDashBoardServiceEntityObj: LoadDashBoardServiceEntityObj = {
      WHID: this.state.WarehouseID,
      LoginAccountId: this.UserInfo.AccountID,
      LoginUserId: this.UserInfo.UserID,
      LoginTanentId: this.UserInfo.TenantID,
      Date:this.state.Date
    };
    LoadDashBoardStoreCreation.LoadGenericrDataStore(
      LoadDashBoardServiceEntityObj
    );
  }
  onPieEnter = (_: any, index: any) => {
    this.setState({
      activeIndex: index,
    });
  };
  // onPieLeave=()=>{
  //     this.setState({
  //         activeIndex: 0
  //     });
  // }

  StockonPieEnter = (_: any, index: any) => {
    this.setState({
      StockactiveIndex: index,
    });
  };
  async componentDidMount() {
    document.title = "FalconWMS - Dashboard";
    let LoadDashBoardServiceEntityObj: LoadDashBoardServiceEntityObj = {
      WHID: this.state.WarehouseID,
      LoginAccountId: this.UserInfo.AccountID,
      LoginUserId: this.UserInfo.UserID,
      LoginTanentId: this.UserInfo.TenantID,
      Date: this.state.Date
    };
    await LoadDashBoardStoreCreation.LoadGenericrDataStore(LoadDashBoardServiceEntityObj);
  }
  render() {

    const {
      // LoadDashBoardReturns,
      // LoadDashBoardOBD,
      // LoadDashBoardIBD,
      LoadDashBoardPO_Count,
      LoadDashBoardSO_COUNT,
      LoadDashBoardPO_Count_Open,
      LoadDashBoardSO_COUNT_Open,
      LoadDashBoardSUPPLIERRETURN_COUNT,
      LoadDashBoardCancelledSo_Count,
      LoadDashBoardCUSTOMERRETURN_COUNT,
      LoadDashBoardITEM_COUNT,
      LoadDashBoardWHOccupancy,
      LoadDashBoardstock,
      LoadDashBoardLegger,
      LoadDashBoardLeader
    } = LoadDashBoardStoreCreation;
    const COLORS = [
      "#EB5754",
      "#775DEC",
      "#f06548",
      "#f7b84b",
      "#0ab39c",
    ];

    const LeaderColumns: any = [
      { title: `${this.props.t('DashBoard.Operator')}`, dataIndex: 'Operator', width: '100' },
      { title: `${this.props.t('DashBoard.Pick_Qty')}`, dataIndex: 'Pick Qty.', width: '50px', align: 'right' },
      { title: `${this.props.t('DashBoard.Putaway_Qty')}`, dataIndex: 'Putaway Qty.', width: '50px', align: 'right' }
    ]

    const LeggerColumns: any = [
      { title: `${this.props.t('DashBoard.Operator')}`, dataIndex: 'Operator', width: '100px' },
      { title: `${this.props.t('DashBoard.Pick_Qty')}`, dataIndex: 'Pick Qty.', width: '50px', align: 'right' },
      { title: `${this.props.t('DashBoard.Putaway_Qty')}`, dataIndex: 'Putaway Qty.', width: '50px', align: 'right' }
    ]

    return (
      <div>
        <Content>
          <div className="site-layout-background layout_height">
            <div className="dashboard">
              <div className="dash">
                <Card style={{ margin: "0px 15px 0px 15px" }}>
                  <Row gutter={16}>
                    <Col className="gutter-row Dashboard-card-col" span={9}>
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {this.props.t('DashBoard.DashBoard')}
                      </span>
                    </Col>
                    <Col className="gutter-row Dashboard-card-col" span={6}>
                      <div className="divFlex">
                        <Autocomplete
                          options={this.state.dropdownData}
                          onValueSelect={this.onSelectDashBOard}
                          defaultValue={this.state.warehouse}
                          AutocompleteOnChange={this.DashBoardAutoComplete}
                          PrefixMinLenght={0}
                          key={this.state.warehouse}
                          aPICallRequired={true}
                          //key={this.state.ActivityRateGroup}
                          label={this.props.t('DashBoard.Select_Warehouse')}
                        />
                        <SearchOutlined className="Icon_Align" />
                      </div>
                    </Col>
                    <Col className="gutter-row Dashboard-card-col" span={6}>
                      <div className="divFlex">
                        <input type="month"
                          name="Date"
                          autoComplete="off"
                          min="2022-11"
                          max={new Date().toJSON().slice(0, 7)}
                          value={this.state.Date !== '' || this.state.Date !== null ? moment(this.state.Date).format("YYYY-MM") : ''}
                          onChange={this.handleChange}
                        />
                        <CalendarOutlined className="Icon_Align" />
                      </div>
                    </Col>
                    <Col className="gutter-row button-alignment Dashboard-card-col" span={3}>
                      <Button onClick={() => this.GetDashBoard_on_Search()}>
                        {this.props.t('DashBoard.Search')}
                        <SearchOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </div>
              <br />
              <Row>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.MTD')}</p>
                        {LoadDashBoardPO_Count.map((item) => {
                          return <h1 key={item.PO_Count}>{item.PO_Count}</h1>;
                        })}
                      </Col>

                      <Col className="inbound" style={{ width: "50%" }}>
                        <img
                          src={inbound}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p
                        style={{
                          position: "relative",
                          fontWeight: "bold"
                        }}
                      >
                        {this.props.t('DashBoard.Inbound')}
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.MTD')}</p>
                        {LoadDashBoardSO_COUNT.map((item) => {
                          return <h1 key={item.SO_COUNT}>{item.SO_COUNT}</h1>;
                        })}
                      </Col>
                      <Col className="outbound" style={{ width: "50%" }}>
                        <img
                          src={outbound}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p style={{ position: "relative", fontWeight: "bold" }}>
                        {this.props.t('DashBoard.Outbound')}
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.Today')}</p>
                        {LoadDashBoardPO_Count_Open.map((item) => {
                          return (
                            <h1 key={item.PO_Count_Open}>
                              {item.PO_Count_Open}
                            </h1>
                          );
                        })}
                      </Col>
                      <Col className="inward" style={{ width: "50%" }}>
                        <img
                          src={inward}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p
                        style={{
                          position: "relative",
                          fontWeight: "bold"
                        }}
                      >
                        {this.props.t('DashBoard.Inward_Inprocess')}
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.Today')}</p>
                        {LoadDashBoardSO_COUNT_Open.map((item) => {
                          return (
                            <h1 key={item.SO_COUNT_Open}>
                              {item.SO_COUNT_Open}
                            </h1>
                          );
                        })}
                      </Col>
                      <Col className="outward" style={{ width: "50%" }}>
                        <img
                          src={outward}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p
                        style={{
                          position: "relative",
                          fontWeight: "bold"
                        }}
                      >
                        {/* //po inbound//so outboundactive_sku item_count */}
                        {this.props.t('DashBoard.Outward_Inprocess')}
                      </p>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: "25%" }}>
                  <Card
                    title={this.props.t('DashBoard.WH_Occupancy')}
                    className="PieChart"
                    style={{ marginRight: "0px" }}
                  >
                    <PieChart width={230} height={170}>
                      {/* <Pie data={pie} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" /> */}
                      <Pie
                        // activeIndex={this.state.activeIndex}
                        // activeShape={renderActiveShape}
                        data={LoadDashBoardWHOccupancy}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={80}
                        paddingAngle={4}
                        fill="#8884d8"
                        dataKey="value"
                        nameKey="name"
                        onMouseEnter={this.onPieEnter}
                      // onMouseLeave={this.onPieLeave}
                      >
                        {LoadDashBoardWHOccupancy ? LoadDashBoardWHOccupancy.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )) : <></>}
                      </Pie>
                      <Tooltip itemStyle={{ color: "var(--text)" }} />
                    </PieChart>
                  </Card>
                </Col>
                <Col style={{ width: "25%" }}>
                  <Card
                    title={this.props.t('DashBoard.Stock_State')}
                    className="PieChart"
                    style={{ marginLeft: "0px" }}
                  >
                    <PieChart width={230} height={170}>
                      {/* <Pie data={pie} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" /> */}
                      <Pie
                        // activeIndex={this.state.StockactiveIndex}
                        // activeShape={renderActiveShape}
                        data={LoadDashBoardstock}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={80}
                        paddingAngle={4}
                        fill="orange"
                        dataKey="value"
                        onMouseEnter={this.StockonPieEnter}
                      >
                        {LoadDashBoardstock ? LoadDashBoardstock.map((entry, index, Label) => (
                          <Cell
                            key={`cell-${Label}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )) : <></>}
                      </Pie>
                      <Tooltip itemStyle={{ color: "var(--text)" }} />
                    </PieChart>
                  </Card>
                </Col>
                <Col style={{ width: "50%" }}>
                  <Card className="LeaderChart">
                    <Row>
                      <Col span={12} style={{ borderRight: '1px solid #b6b6b6' }}>
                        <Card title={this.props.t('DashBoard.Leader')} className="Leader">
                          {
                            LoadDashBoardLeader ?

                              <Table
                                columns={LeaderColumns}
                                dataSource={LoadDashBoardLeader}
                                className="LeaderTable"
                                style={{ textAlign: "center", padding: "30px 0px" }}
                                pagination={false}
                              />
                              :
                              <Empty />
                          }

                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card title={this.props.t('DashBoard.Lagger')} className="Leader">
                          {
                            LoadDashBoardLegger ?
                              <Table
                                columns={LeggerColumns}
                                dataSource={LoadDashBoardLegger}
                                className="LaggerTable"
                                style={{ textAlign: "center", padding: "30px 0px" }}
                                pagination={false} />
                              :
                              <Empty />
                          }
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.MTD')}</p>
                        {LoadDashBoardITEM_COUNT.map((item) => {
                          return (
                            <h1 key={item.ITEM_COUNT}>{item.ITEM_COUNT}</h1>
                          );
                        })}
                      </Col>
                      <Col className="active_sku" style={{ width: "50%" }}>
                        <img
                          src={active_sku}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p style={{ position: "relative", fontWeight: "bold" }}>
                        {this.props.t('DashBoard.Active_SKUs')}
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.MTD')}</p>
                        {LoadDashBoardCancelledSo_Count.map((item) => {
                          return (
                            <h1 key={item.CancelledSo_Count}>
                              {item.CancelledSo_Count}
                            </h1>
                          );
                        })}
                      </Col>
                      <Col className="so_cancel" style={{ width: "50%" }}>
                        <img
                          src={so_cancel}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p style={{ position: "relative", fontWeight: "bold" }}>
                        {this.props.t('DashBoard.SOs_Cancelled')}
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.MTD')}</p>
                        {LoadDashBoardSUPPLIERRETURN_COUNT.map((item) => {
                          return (
                            <h1 key={item.SUPPLIERRETURN_COUNT}>
                              {item.SUPPLIERRETURN_COUNT}
                            </h1>
                          );
                        })}
                      </Col>
                      <Col
                        className="supplier_returns"
                        style={{ width: "50%" }}
                      >
                        <img
                          src={supplier_returns}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p style={{ position: "relative", fontWeight: "bold" }}>
                        {this.props.t('DashBoard.Supplier_Returns')}
                      </p>
                    </Row>
                  </Card>
                </Col>
                <Col className="dashboardcard">
                  <Card className="card11">
                    <Row>
                      <Col
                        className="WeeklyDataCard"
                      >
                        <p style={{ paddingBottom: "10px" }}>{this.props.t('DashBoard.MTD')}</p>
                        {LoadDashBoardCUSTOMERRETURN_COUNT.map((item) => {
                          return (
                            <h1 key={item.CUSTOMERRETURN_COUNT}>
                              {item.CUSTOMERRETURN_COUNT}
                            </h1>
                          );
                        })}
                      </Col>
                      <Col
                        className="customer_returns"
                        style={{ width: "50%" }}
                      >
                        <img
                          src={customer_returns}
                          alt=""
                          style={{ width: "23px", height: "23px" }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <p style={{ position: "relative", fontWeight: "bold" }}>
                        {this.props.t('DashBoard.Customer_Returns')}
                      </p>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <HourlyDashBoard />
              {/* <Row>
                <Card title='Inbound - Efficiency' className="AreaChart">
                <ResponsiveContainer width="100%" aspect={3}>
                    <AreaChart
                      width={730}
                      height={250}
                      data={LoadDashBoardIBD}
                      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="DayInDATE" />
                      <YAxis dataKey="Inb_Count" />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="Inb_Count"
                        stroke="green"
                        fill="green"
                        activeDot={{ r: 8 }}
                        dot={{
                          stroke: "green",
                          strokeWidth: 1,
                          r: 4,
                          strokeDasharray: "",
                        }}
                      />
                      <Legend />
                    </AreaChart>
                  </ResponsiveContainer>
                </Card>
              </Row> */}
              {/* <Row>
                <Card title='Outbound - Efficiency' className="AreaChart">
                <ResponsiveContainer width="100%" aspect={3}>
                    <AreaChart
                      width={730}
                      height={350}
                      data={LoadDashBoardOBD}
                      stackOffset="expand"
                      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 1" />
                      <XAxis
                        dataKey="DayInDATE"
                        tickCount={10}
                        style={{ paddingTop: "50px", color: "green" }}
                      />
                      <YAxis dataKey="OBD_Count" />
                      <Legend />
                      <Tooltip />
                      {/* <Area type="monotone" dataKey="DayInDATE" stroke="green" fill="red" activeDot={{r:8}}/> */}
              {/* <Area
                        type="monotone"
                        dataKey="OBD_Count"
                        stroke="#fab725"
                        fill="#fab725"
                        activeDot={{ r: 8 }}
                        dot={{
                          stroke: "#fab725",
                          strokeWidth: 1,
                          r: 4,
                          strokeDasharray: "",
                        }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Card>
              </Row>  */}
              {/* <Row>
                <Card title='Outward Order Flow' className="AreaChart">
                <ResponsiveContainer width="100%" aspect={3}>
                  <AreaChart
                    width={730}
                    height={250}
                    data={LoadDashBoardReturns}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="TIME" />
                    <YAxis dataKey="SO_count" />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="SO_count"
                      stroke="green"
                      fill="green"
                      activeDot={{ r: 8 }}
                      dot={{
                        stroke: "green",
                        strokeWidth: 1,
                        r: 4,
                        strokeDasharray: "",
                      }}
                    />
                    <Legend />
                  </AreaChart>
                </ResponsiveContainer>
                </Card>
              </Row> */}
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

export default withTranslation()(Dashboard)
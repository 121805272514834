//import { EndPoints } from './Dev';
// import { EndPoints } from './QA';
// import { EndPoints } from './Stage';
 import { EndPoints } from './Prod';

export const END_POINT_SSO = EndPoints.END_POINT_SSO
export const END_POINT_SKUBIQ_SECURITY = EndPoints.END_POINT_SKUBIQ_SECURITY
export const END_POINT_MasterData = EndPoints.END_POINT_MasterData
export const END_POINT_Orders = EndPoints.END_POINT_Orders
export const END_POINT_Inbound = EndPoints.END_POINT_Inbound
export const END_POINT_Inventory = EndPoints.END_POINT_Inventory
export const END_POINT_Outbound = EndPoints.END_POINT_Outbound
export const END_POINT_3PL = EndPoints.END_POINT_3PL
export const END_POINT_ADMINSTRATION = EndPoints.END_POINT_ADMINSTRATION
export const END_POINT_REPORTS = EndPoints.END_POINT_REPORTS
export const END_POINT_S3_Bucket = EndPoints.END_POINT_S3_Bucket
export const JSPrintMngrScript = EndPoints.JSPrintMngrScript
export const PrintFunctionScript = EndPoints.PrintFunctionScript
export const FlipKart_Redirect_URL = EndPoints.FlipKart_Redirect_URL

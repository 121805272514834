import React, { Component } from 'react';
import PDF0 from "../../../Assets/Documents/HelpDeskPDF/FinalPDF.pdf"
import PDF1 from "../../../Assets/Documents/HelpDeskPDF/a.pdf";
import PDF2 from "../../../Assets/Documents/HelpDeskPDF/SKUBIQ MaterialTransfer_ UserManual.pdf";
import PDF3 from "../../../Assets/Documents/HelpDeskPDF/SKUBIQ SO_Outbound_ UserManual.pdf";
import PDF4 from "../../../Assets/Documents/HelpDeskPDF/SKUBIQ Supplier returns_User Manual.pdf";
import PDF5 from "../../../Assets/Documents/HelpDeskPDF/SKUBIQ_CycleCount_UserManual.pdf";
class ExportPDF extends Component {
   // constructor(props){
   //    super(props)
   // }
   render() {
      // debugger
      const path = this.props.menupath
      return (
         <div>
            <iframe
               style={{ width: "497px", height: "666px", overflow: 'hidden' }}
               src={
                  path === 'PDF1' ? PDF1 :
                  path === 'PDF0' ? PDF0 :
                  path === 'PDF2' ? PDF2 :
                  path === 'PDF3' ? PDF3 :
                  path === 'PDF4' ? PDF4 :
                  path === 'PDF5' ? PDF5 :
                  PDF0 }
               type='application/pdf'
               title='title'
            />:
         </div>
      );
   }
}

export default ExportPDF;
import { JSPrintMngrScript, PrintFunctionScript } from './Endpoint';

type EnvironmentValiablesEntity = {
    ClientID : string,
    Client_Secret : string
}

export const EnvironmentValiables : EnvironmentValiablesEntity = {
    ClientID : '1E98A5E3-8CF0-445F-8399-76975DEE375F',
    Client_Secret : 'wrP9cOqV3f88bog8gjK9BQ=='
}

type ExcelEntity = {
    ExcelHeaderBGColor : string,
    ExcelHeaderBGColor1 : string,
    ExcelHeaderMandatoryColor : string
}

export const Excel : ExcelEntity = {
    ExcelHeaderBGColor : 'FF99CCFF',
    ExcelHeaderBGColor1 : 'FFFF0000',
    ExcelHeaderMandatoryColor : 'FFFF0000'
}

export const Excel1 : ExcelEntity = {
    ExcelHeaderBGColor : 'FF76B947',
    ExcelHeaderBGColor1 : 'FFFF0000',
    ExcelHeaderMandatoryColor : 'FF00FF00'
}

export type JSPrintManagerUtils = {
    JSPrintMngrScript : string
    bluebirdScript : string
    jqueryScript : string
    PrintFunctionScript:string
}

export const JSPrintManagerScriptLinks : JSPrintManagerUtils  = {
    JSPrintMngrScript : JSPrintMngrScript,
    bluebirdScript : 'https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.5/bluebird.min.js',
    jqueryScript : 'https://code.jquery.com/jquery-3.2.1.slim.min.js',
    PrintFunctionScript: PrintFunctionScript
}

export const pageSizeOptions = [ '25', '50','75','100'];


//Light
// export const PO_SO_Status_Colors = {
//     Open : '#85C1E9',
//     Processing : '#F7DC6F',
//     Closed : '#82E0AA',
//     Canceled : '#F1948A'
// }

//Dark
// export const PO_SO_Status_Colors = {
//     Open : '#5DADE2',
//     Processing : '#F4D03F',
//     Closed : '#58D68D',
//     Canceled : '#EC7063'
// }

//colors
export const PO_SO_Status_Colors = {
    Open : 'blue',
    Processing : 'gold',
    Closed : 'green',
    Canceled : 'red'
}

export const Actice_InActive_Colors = {
    Actice : 'green',
    InActive : 'red'
}

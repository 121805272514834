import { observable } from "mobx";
import { IRouterData } from "./../App";

export class RouterDataStore {

    @observable
    public router: IRouterData[] = [
        {
            id: '0',
            path: '/',
            name: 'Dashboard',
            fileLocation: './Modules/General/Dashboard/Dashboard',
            isActive: true
        },
        {
            id: '1',
            path: '/ForgetPassword',
            name: 'ForgetPassword',
            fileLocation: './Modules/General/Login/ForgetPassword',
            isActive: true
        },
        {
            id: '2',
            path: '/Profile',
            name: 'ForgetPassword',
            fileLocation: './Modules/General/Login/Profile',
            isActive: true
        },
        {
            id: '3',
            path: '/Error',
            name: 'Error',
            fileLocation: './Modules/General/Error/Error',
            isActive: true
        },
        {
            id: '3',
            path: '/Encryption',
            name: 'Encryption',
            fileLocation: './Modules/General/Encryption/Encryption',
            isActive: true
        },
        {
            id: '4',
            path: '/Default',
            name: 'Dashboard',
            fileLocation: './Modules/General/Dashboard/Dashboard',
            isActive: true
        },
        {
            id: '5',
            path: '/Login',
            name: 'Home',
            fileLocation: './Modules/General/Login/Login',
            isActive: true,
            params: '/:email/:password'
        },
        {
            id: '209',
            path: '/MenuLinks',
            name: 'Home',
            fileLocation: './Modules/General/MenuLink/MenuLink',
            isActive: true,
        },
        // TPL
        {
            id: '6',
            path: '/MasterData/AccountList',
            name: 'Home',
            fileLocation: './Modules/TPL/AccountList/AccountList',
            isActive: true
        },
        {
            id: '7',
            path: '/MasterData/AccountDetails',
            name: 'list',
            fileLocation: './Modules/TPL/NewAccount/NewAccount',
            isActive: true,
            params: '/:id'
        },
        {
            id: '8',
            path: '/MasterData/NewAccount',
            name: 'list',
            fileLocation: './Modules/TPL/NewAccount/NewAccount',
            isActive: true
        },
        {
            id: '98',
            path: '/MasterData/NewWarehouse',
            name: 'list',
            fileLocation: './Modules/TPL/NewWarehouse/NewWarehouse',
            isActive: true,
            params: '/:AID/:WID'
        },
        {
            id: '9',
            path: '/MasterData/TenantList',
            name: 'list',
            fileLocation: './Modules/TPL/TenantList/TenantList',
            isActive: true
        },
        {
            id: '10',
            path: '/MasterData/EditTenantRegistration',
            name: 'list',
            fileLocation: './Modules/TPL/TenantRegistration/TenantRegistration',
            isActive: true,
            params: '/:TID'
        },
        {
            id: '11',
            path: '/MasterData/NewTenantRegistration',
            name: 'list',
            fileLocation: './Modules/TPL/TenantRegistration/TenantRegistration',
            isActive: true,
        },
        //mMaterialManagement
        {
            id: '12',
            path: '/MasterData/SupplierList',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/SupplierList/SupplierList',
            isActive: true
        },
        {
            id: '13',
            path: '/MasterData/NewSupplierRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/SupplierRequest/SupplierRequest',
            isActive: true
        },
        {
            id: '14',
            path: '/MasterData/EditSupplierRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/SupplierRequest/SupplierRequest',
            isActive: true,
            params: '/:SID'
        },
        {
            id: '15',
            path: '/MasterData/CustomerList',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/CustomerList/CustomerList',
            isActive: true
        },
        {
            id: '16',
            path: '/MasterData/EditCustomer',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/Customer/Customer',
            isActive: true,
            params: '/:CID'
        },

        {
            id: '17',
            path: '/MasterData/NewCustomer',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/Customer/Customer',
            isActive: true
        },
        {
            id: '18',
            path: '/MasterData/MaterialMasterList',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/MaterialMasterList/MaterialMasterList',
            isActive: true
        },
        {
            id: '19',
            path: '/MasterData/CreateItemMasterRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/ItemMasterRequest/ItemMasterRequest',
            isActive: true,
        },
        {
            id: '20',
            path: '/MasterData/EditItemMasterRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/ItemMasterRequest/ItemMasterRequest',
            isActive: true,
            params: '/:MMID'
        },

        {
            id: '21',
            path: '/MasterData/CopyItemMasterRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/ItemMasterRequest/ItemMasterRequest',
            isActive: true,
            params: '/:MMID/:copy'
        },
        {
            id: '22',
            path: '/MasterData/KitPlannerList',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/KitPlannerList/KitPlannerList',
            isActive: true
        },
        {
            id: '23',
            path: '/MasterData/EditKitPlannerRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/KitPlannerRequest/KitPlannerRequest',
            isActive: true,
            params: '/:KID'

        },
        {
            id: '24',
            path: '/MasterData/CreateKitPlannerRequest',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/KitPlannerRequest/KitPlannerRequest',
            isActive: true,

        },
        {
            id: '25',
            path: '/MasterData/LocationManager',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/LocationManager/LocationManager',
            isActive: true,
            params: '/:Wid/:Zid'
        },
        {
            id: '26',
            path: '/MasterData/LocationManager',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/LocationManager/LocationManager',
            isActive: true
        },{
            id: '208',
            path: '/Administration/UserBinConfiguration',
            name: 'WarehouseLocationManager',
            fileLocation: './Modules/mMaterialManagement/UserBinConfiguration/UserBinConfiguration',
            isActive: true
        },
        // mOrders
        {
            id: '27',
            path: '/Orders/POList',
            name: 'list',
            fileLocation: './Modules/mOrders/POList/POList',
            isActive: true
        },
        {
            id: '28',
            path: '/Orders/PODetailsInfo',
            name: 'list',
            fileLocation: './Modules/mOrders/PODetailsInfo/PODetailsInfo',
            isActive: true,
            params: '/:poid/:tid'
        },
        {
            id: '29',
            path: '/Orders/SOList',
            name: 'list',
            fileLocation: './Modules/mOrders/SOList/SOList',
            isActive: true
        },
        {
            id: '30',
            path: '/Orders/CreateSalesOrderInfo',
            name: 'list',
            fileLocation: './Modules/mOrders/SalesOrderInfo/SalesOrderInfo',
            isActive: true,

        },
        {
            id: '31',
            path: '/Orders/SalesOrderInfo',
            name: 'list',
            fileLocation: './Modules/mOrders/SalesOrderInfo/SalesOrderInfo',
            isActive: true,
            params: '/:soid/:tid'

        },
        {
            id: '42',
            path: '/Orders/Returns/CustomerReturns',
            name: 'list',
            fileLocation: './Modules/mInventory/CustomerReturns/CustomerReturns',
            isActive: true
        },
        {
            id: '43',
            path: '/Orders/Returns/SupplierReturns',
            name: 'list',
            fileLocation: './Modules/mInventory/SupplierReturn/SupplierReturn',
            isActive: true
        },
        {
            id: '96',
            path: '/Orders/HouseKeeping/InternalTransfer',
            name: 'list',
            fileLocation: './Modules/mInventory/InternalTransfer/InternalTransfer',
            isActive: true
        },
        {
            id: '61',
            path: '/Orders/HouseKeeping/FastMovingLocsTransfer',
            name: 'list',
            fileLocation: './Modules/mInventory/FastMovingLocsTransfer/FastMovingLocsTransfer',
            isActive: true
        },
        {
            id: '137',
            path: '/Orders/HouseKeeping/WorkOrder',
            name: 'list',
            fileLocation: './Modules/mInventory/WorkOrder/WorkOrder',
            isActive: true
        },
        {
            id: '137',
            path: '/Orders/HouseKeeping/WorkOrderRequest',
            name: 'list',
            fileLocation: './Modules/mInventory/WorkOrderRequest/WorkOrderRequest',
            isActive: true,
            params: '/:id'
        },
        {
            id: '62',
            path: '/Orders/HouseKeeping/InternalTransferRequest',
            name: 'list',
            fileLocation: './Modules/mInventory/InternalTransferRequest/InternalTransferRequest',
            isActive: true,
            params: '/:id'
        },
        {
            id: '85',
            path: '/Orders/HouseKeeping/CycleCountList',
            name: 'list',
            fileLocation: './Modules/mInventory/CycleCountList/CycleCountList',
            isActive: true,
        },
        {
            id: '86',
            path: '/Orders/HouseKeeping/CycleCountDetails',
            name: 'list',
            fileLocation: './Modules/mInventory/CycleCountDetails/CycleCountDetails',
            isActive: true,
            params: '/:CCID'
        },
        {
            id: '87',
            path: '/Orders/HouseKeeping/CycleCountTransaction',
            name: 'list',
            fileLocation: './Modules/mInventory/CycleCountTransaction/CycleCountTransaction',
            isActive: true
        },

        {
            id: '106',
            path: '/Orders/HouseKeeping/CCBlockedLocations',
            name: 'list',
            fileLocation: './Modules/mInventory/CCBlockedLocations/CCBlockedLocations',
            isActive: true,
            params: '/:CCID/:CCAID/:CCACID'
        },
        {
            id: '66',
            path: '/Orders/HouseKeeping/CycleCountReport',
            name: 'list',
            fileLocation: './Modules/mInventory/CycleCountReport/CycleCountReport',
            isActive: true,
            params: '/:ID'
        },
        ///////////////////////////////////////////////////////
        //mInbound
        {
            id: '99',
            path: '/Inbound/InboundDetails',
            name: 'list',
            fileLocation: './Modules/mInbound/InboundDetails/InboundDetails',
            isActive: true,
        },
        {
            id: '100',
            path: '/Inbound/InboundDetails',
            name: 'list',
            fileLocation: './Modules/mInbound/InboundDetails/InboundDetails',
            isActive: true,
            params: '/:inbID'
        },
        {
            id: '34',
            path: '/Inbound/NewInboundSearch',
            name: 'list',
            fileLocation: './Modules/mInbound/InboundSearch/InboundSearch',
            isActive: true
        },
        {
            id: '59',
            path: '/Inbound/RTReport',
            name: 'list',
            fileLocation: './Modules/mInbound/RTReport/RTReport',
            isActive: true,
            params: '/:inbid'
            //params: '/:inbid/:lc/:tid'
        },
        {
            id: '58',
            path: '/Inbound/InboundImport',
            name: 'list',
            fileLocation: './Modules/mInbound/InboundImports/InboundImports',
            isActive: true
        },
        {
            id: '58',
            path: '/Inbound/InboundTracking',
            name: 'list',
            fileLocation: './Modules/mInbound/InboundTracking/InboundTracking',
            isActive: true
        },
        {
            id: '33',
            path: '/Inbound/RevertInbound',
            name: 'list',
            fileLocation: './Modules/mInbound/RevertInbound/RevertInbound',
            isActive: true
        },
        {
            id: '105',
            path: '/Inbound/GoodsIn',
            name: 'list',
            fileLocation: './Modules/mInventory/GoodsIn/GoodsIn',
            isActive: true,
            //params:'/:inbID'
            params: '/:inbID/:mmid/:lno/:PODH/:SIID/:SIDETID'
        },
        ///inventory
        {
            id: '39',
            path: '/Inventory/CurrentStock',
            name: 'list',
            fileLocation: './Modules/mReports/CurrentStockReport/CurrentStockReport',
            isActive: true
        },
        {
            id: '40',
            path: '/Inventory/MiscellaneousReceipt',
            name: 'list',
            fileLocation: './Modules/mInventory/MiscellaneousReceipt/MiscellaneousReceipt',
            isActive: true
        },
        {
            id: '129',
            path: '/Inventory/StockAdjustmentImport',
            name: 'list',
            fileLocation: './Modules/mInventory/StockAdjustmentImport/StockAdjustmentImport',
            isActive: true
        },
        {
            id: '41',
            path: '/Inventory/MiscellaneousIssue',
            name: 'list',
            fileLocation: './Modules/mInventory/MiscellaneousIssue/MiscellaneousIssue',
            isActive: true
        },
        // {
        //     id: '96',
        //     path: '/Orders/HouseKeeping/InternalTransfer',
        //     name: 'list',
        //     fileLocation: './Modules/mInventory/InternalTransfer/InternalTransfer',
        //     isActive: true
        // },
        // {
        //     id: '62',
        //     path: '/Orders/HouseKeeping/InternalTransferRequest',
        //     name: 'list',
        //     fileLocation: './Modules/mInventory/InternalTransferRequest/InternalTransferRequest',
        //     isActive: true,
        //     params: '/:id'
        // },
        //Administration
        {
            id: '45',
            path: '/Administration/CreateStorageCondition',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/CreateStorageCondition/CreateStorageCondition',
            isActive: true
        },
        {
            id: '46',
            path: '/Administration/MaterialType',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/MaterialType/MaterialType',
            isActive: true
        },
        {
            id: '47',
            path: '/Administration/MGroupMaster',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/MGroupMaster/MGroupMaster',
            isActive: true
        },
        {
            id: '48',
            path: '/Administration/PalletManagement',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/ContainerManagement/ContainerManagement',
            isActive: true
        },

        {
            id: '53',
            path: '/Administration/CreateClearanceCompany',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/CreateClearanceCompany/CreateClearanceCompany',
            isActive: true
        },
        {
            id: '54',
            path: '/Administration/CreateFreightCompany',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/CreateFreightCompany/CreateFreightCompany',
            isActive: true
        },
        {
            id: '55',
            path: '/Administration/AttributeMaster',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/AttributeMaster/AttributeMaster',
            isActive: true
        },
        {
            id: '56',
            path: '/Administration/AttributesDetails',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/AttributesDetails/AttributesDetails',
            isActive: true,
        },
        {
            id: '57',
            path: '/Administration/EditAttributesDetails',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/AttributesDetails/AttributesDetails',
            isActive: true,
            params: '/:Id'
        },
        //mInbound

        //TPL
        {
            id: '44',
            path: '/TPL/TariffCreation',
            name: 'list',
            fileLocation: './Modules/TPL/TariffCreation/TariffCreation',
            isActive: true
        },
        {
            id: '50',
            path: '/TPL/Taxation',
            name: 'list',
            fileLocation: './Modules/TPL/Taxation/Taxation',
            isActive: true
        },
        {
            id: '51',
            path: '/TPL/ActivityTaxation',
            name: 'list',
            fileLocation: './Modules/TPL/ActivityTaxation/ActivityTaxation',
            isActive: true
        },
        {
            id: '52',
            path: '/TPL/TariffAllocation',
            name: 'list',
            fileLocation: './Modules/TPL/TariffAllocation/TariffAllocation',
            isActive: true
        },

        // mOutbound
        {
            id: '35',
            path: '/Outbound/OutboundImport',
            name: 'list',
            fileLocation: './Modules/mOutbound/OutboundImports/OutboundImports',
            isActive: true
        },
        {
            id: '36',
            path: '/Outbound/OutboundCreation',
            name: 'list',
            fileLocation: './Modules/mOutbound/OutboundCreation/OutboundCreation',
            isActive: true
        },
        {
            id: '37',
            path: '/Outbound/OutboundRevert',
            name: 'list',
            fileLocation: './Modules/mOutbound/OutboundRevert/OutboundRevert',
            isActive: true
        },
        {
            id: '38',
            path: '/Outbound/ReleaseOutbound',
            name: 'list',
            fileLocation: './Modules/mOutbound/ReleaseOutbound/ReleaseOutbound',
            isActive: true
        },
        // mReports

        // mInventory
        //TPL




        // mOutbound
        {
            id: '60',
            path: '/Outbound/OutboundSearchNew',
            name: 'list',
            fileLocation: './Modules/mOutbound/OutboundSearch/OutboundSearch',
            isActive: true
        },
        // mInventory


        //mOutbound
        {
            id: '63',
            path: '/Outbound/OutboundTracking',
            name: 'list',
            fileLocation: './Modules/mOutbound/OutboundTracking/OutboundTracking',
            isActive: true
        },
        //mReports

        {
            id: '65',
            path: '/Outbound/LoadSheetReport',
            name: 'list',
            fileLocation: './Modules/mReports/LoadSheetReport/LoadSheetReport',
            isActive: true
        },
        //mInventory

        //mReports

        //TPL

        //mReports

        {
            id: '80',
            path: '/mReports/CurrentStockRPRT',
            name: 'list',
            fileLocation: './Modules/mReports/CurrentStockRPRT/CurrentStockRPRT',
            isActive: true
        },


        //mInventory
        //mOutbound
        {
            id: '88',
            path: '/Outbound/BulkOutboundRelease',
            name: 'list',
            fileLocation: './Modules/mOutbound/BulkOutboundRelease/BulkOutboundRelease',
            isActive: true
        },//mReports


        {
            id: '91',
            path: '/Reports/ReceiptConfirmationReport',
            name: 'list',
            fileLocation: './Modules/mReports/ReceiptConfirmationReport/ReceiptConfirmationReport',
            isActive: true,
            params: '/:inbid'
        },
        {
            id: '107',
            path: '/Inbound/ReceiptConfirmationReport',
            name: 'list',
            fileLocation: './Modules/mInbound/ReceiptConfirmationReport/ReceiptConfirmationReport',
            isActive: true,
            params: '/:inbid'
        },
        //mInventory

        //mReports


        {
            id: '101',
            path: '/Outbound/ReleaseOutboundSKU',
            name: 'list',
            fileLocation: './Modules/mOutbound/ReleaseOBDItems/ReleaseOBDItems',
            isActive: true,
            params: '/:ObdId'
        },
        {
            id: '102',
            path: '/Outbound/OutboundDetails',
            name: 'list',
            fileLocation: './Modules/mOutbound/OutboundDetails/OutboundDetails',
            isActive: true,params: '/:ObId'
        },
        {
            id: '103',
            path: '/Outbound/DeliveryPickNote',
            name: 'list',
            fileLocation: './Modules/mOutbound/DeliveryPickNote/DeliveryPickNote',
            isActive: true,
            params: '/:ObdId'
        },
        {
            id: '104',
            path: '/Outbound/DeliveryPickNoteData',
            name: 'list',
            fileLocation: './Modules/mOutbound/DeliveryPickNoteData/DeliveryPickNoteData',
            isActive: true,
            params: '/:ObdId'
            //params: '/:ObdId/:lc/:tid'
        },



        //mReports
        {
            id: '49',
            path: '/Reports/TPL/MonthlyBillingReport',
            name: 'list',
            fileLocation: './Modules/mReports/MonthlyBillingReport/MonthlyBillingReport',
            isActive: true
        },
        {
            id: '70',
            path: '/Reports/TPL/InvoiceGeneration',
            name: 'list',
            fileLocation: './Modules/TPL/InvoiceGeneration/InvoiceGeneration',
            isActive: true,

        },
        {
            id: '111',
            path: '/Reports/TPL/GSTInvoiceGeneration',
            name: 'list',
            fileLocation: './Modules/TPL/GSTInvoiceGeneration/GSTInvoiceGeneration',
            isActive: true,

        },
        {
            id: '110',
            path: '/Reports/TPL/WeeklyBilling',
            name: 'list',
            fileLocation: './Modules/TPL/WeeklyBilling/WeeklyBilling',
            isActive: true,
            params: '/:TID/:FDATE/:TDATE'
        },
        {
            id: '89',
            path: '/Reports/Inbound/IBSummaryReport',
            name: 'list',
            fileLocation: './Modules/mReports/IBSummaryReport/IBSummaryReport',
            isActive: true
        },
        {
            id: '90',
            path: '/Reports/Inbound/ReceiptPendingReport',
            name: 'list',
            fileLocation: './Modules/mReports/ReceiptPendingReport/ReceiptPendingReport',
            isActive: true
        },
        {
            id: '92',
            path: '/Reports/Outbound/OutboundTransactionsHistory',
            name: 'list',
            fileLocation: './Modules/mReports/OutboundTransactionsHistoryNew/OutboundTransactionsHistoryNew',
            isActive: true
        },
        {
            id: '95',
            path: '/Reports/Outbound/OutboundSummary',
            name: 'list',
            fileLocation: './Modules/mReports/OutboundSummary/OutboundSummary',
            isActive: true
        },
        {
            id: '79',
            path: '/Reports/Inventory/WHStockInfoReport',
            name: 'list',
            fileLocation: './Modules/mReports/WHStockInfoReport/WHStockInfoReport',
            isActive: true
        },
        {
            id: '108',
            path: '/Reports/Inventory/CurrentStockReport',
            name: 'list',
            fileLocation: './Modules/mReports/CurrentStockRPRT/CurrentStockRPRT',
            isActive: true,
            //  params: '/:inbid/:lc/:tid'
        },
        {
            id: '64',
            path: '/Reports/Inventory/ExpiredMaterialList',
            name: 'list',
            fileLocation: './Modules/mReports/ExpiredMaterialListNew/ExpiredMaterialListNew',
            isActive: true,
            //params:'/:id'
        },
        {
            id: '72',
            path: '/Reports/Inventory/MaterialReplenishment',
            name: 'list',
            fileLocation: './Modules/mReports/MaterialReplenishmentNew/MaterialReplenishmentNew',
            isActive: true
        },
        {
            id: '73',
            path: '/Reports/Inventory/MaterialAgeingReport',
            name: 'list',
            fileLocation: './Modules/mReports/MaterialAgeingReportNew/MaterialAgeingReportNew',
            isActive: true
        },
        {
            id: '74',
            path: '/Reports/Inventory/MaterialTrackingReport',
            name: 'list',
            fileLocation: './Modules/mReports/MaterialTrackingReportNew/MaterialTrackingReportNew',
            isActive: true
        },
        {
            id: '93',
            path: '/Reports/Inventory/StockStatementSummary',
            name: 'list',
            fileLocation: './Modules/mReports/StockStatementSummaryNew/StockStatementSummaryNew',
            isActive: true
        },
        {
            id: '81',
            path: '/Reports/Inventory/BinReplenishmentReport',
            name: 'list',
            fileLocation: './Modules/mReports/BinReplenishmentReport/BinReplenishmentReport',
            isActive: true
        },
        {
            id: '82',
            path: '/Reports/Inventory/InventoryVariance',
            name: 'list',
            fileLocation: './Modules/mReports/InventoryVariance/InventoryVariance',
            isActive: true
        },
        {
            id: '83',
            path: '/Reports/Inventory/InventoryDispersion',
            name: 'list',
            fileLocation: './Modules/mReports/InventoryDispersion/InventoryDispersion',
            isActive: true
        },
        {
            id: '94',
            path: '/Reports/Inventory/GRNPending',
            name: 'list',
            fileLocation: './Modules/mReports/GRNPending/GRNPending',
            isActive: true
        },
        {
            id: '84',
            path: '/Reports/Warehouse/WarehouseOperatorActivity',
            name: 'list',
            fileLocation: './Modules/mReports/WarehouseOperatorActivityNew/WarehouseOperatorActivityNew',
            isActive: true
        },
        {
            id: '75',
            path: '/Reports/Warehouse/SupplierPerformance',
            name: 'list',
            fileLocation: './Modules/mReports/SupplierPerformanceNew/SupplierPerformanceNew',
            isActive: true
        },
        {
            id: '97',
            path: '/Reports/Audit/LogAuditReport',
            name: 'list',
            fileLocation: './Modules/mReports/LogAuditReportNew/LogAuditReportNew',
            isActive: true
        },
        {
            id: '77',
            path: '/Reports/Audit/SkipLog',
            name: 'list',
            fileLocation: './Modules/mReports/SkipLog/SkipLog',
            isActive: true
        },

        {
            id: '78',
            path: '/Reports/Audit/LostAndFoundReport',
            name: 'list',
            fileLocation: './Modules/mReports/LostAndFoundReport/LostAndFoundReport',
            isActive: true
        },
        {
            id: '109',
            path: '/Orders/HouseKeeping/CycleCountReport',
            name: 'list',
            fileLocation: './Modules/mInventory/CycleCountReport/CycleCountReport',
            isActive: true,
        },
        {
            id: '120',
            path: '/Orders/HouseKeeping/StockAdjustment',
            name: 'list',
            fileLocation: './Modules/mInventory/StockAdjustment/StockAdjustment',
            isActive: true,
            params: '/:ID'
        },

        {
            id: '71',
            path: '/Reports/Returns/CustomerReturns',
            name: 'list',
            fileLocation: './Modules/mReports/CustomersReturnsNew/CustomersReturnsNew',
            isActive: true
        },
        {
            id: '76',
            path: '/Reports/Returns/SupplierReturns',
            name: 'list',
            fileLocation: './Modules/mReports/SupplierReturnsNew/SupplierReturnsNew',
            isActive: true
        },
        {
            id: '69',
            path: '/Reports/Performance/PutawayPerformanceReport',
            name: 'list',
            fileLocation: './Modules/mReports/PutawayPerforamcneReprot/PutawayPerforamcneReprot',
            isActive: true
        },
        {
            id: '67',
            path: '/Reports/Performance/OrderProcessReport',
            name: 'list',
            fileLocation: './Modules/mReports/OrderProcessReport/OrderProcessReport',
            isActive: true
        },
        {
            id: '68',
            path: '/Reports/Performance/PickPerformanceReport',
            name: 'list',
            fileLocation: './Modules/mReports/PerformanceReport/PerformanceReport',
            isActive: true
        },
        {
            id: '111',
            path: '/Outbound/DeliveryPickNotePrint',
            name: 'list',
            fileLocation: './Modules/mOutbound/DeliveryPickNote/DeliveryPickNotePrint',
            isActive: true,
            params: '/:ObdId'
        },
        {
            id: '112',
            path: '/Inbound/ReceiptConfirmationReportPrint',
            name: 'list',
            fileLocation: './Modules/mInbound/ReceiptConfirmationReport/ReceiptConfirmationReportPrint',
            isActive: true,
            params: '/:inbid'
        },
        {
            id: '69',
            path: '/Reports/BarCodeQRCode/BarCodeComponent',
            name: 'list',
            fileLocation: './common/BarCodeQRCode/barcodedockcomponent',
            params: '/:id',
            isActive: true
        },
        {
            id: '100',
            path: '/Administration/ChannelManagement',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/ChannelManagement/ChannelManagement',
            isActive: true,

        },
        {
            id: '101',
            path: '/Administration/EditChannelManagement',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/EditChannelManagement/EditChannelManagement',
            isActive: true,

        },
        {
            id: '101',
            path: '/Administration/EditChannelManagement',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/EditChannelManagement/EditChannelManagement',
            isActive: true,
            params: '/:id',

        },
        {
            id: '121',
            path: '/Administration/VehicleMaster',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/VehicleMaster/VehicleMaster',
            isActive: true
        },
        {
            id: '122',
            path: '/Administration/BasicVehicleDetails',
            name: 'list',
            fileLocation: './Modules/mMaterialManagement/VehicleMaster/BasicVehicleDetails',
            isActive: true

        },
        {
            id: '123',
            path: '/Dashboard/YardControlTower',
            name: 'Dashboard',
            fileLocation: './Modules/General/VehicleDashboard/VehicleDashboard',
            isActive: true

        },
        {
            id: '124',
            path: '/Outbound/BatchPickList/PickListCreation',
            name: 'list',
            fileLocation: './Modules/mOutbound/PickListCreation/PickListCreation',
            isActive: true

        },
        {
            id: '125',
            path: '/Outbound/BatchPickList/PickList',
            name: 'list',
            fileLocation: './Modules/mOutbound/PickList/PickList',
            isActive: true

        },
        {
            id: '126',
            path: '/Outbound/BatchPickList/DeliveryCreation',
            name: 'list',
            fileLocation: './Modules/mOutbound/DeliveryCreation/DeliveryCreation',
            isActive: true

        },
        {
            id: '127',
            path: '/Outbound/BatchPickList/BatchPickList',
            name: 'list',
            fileLocation: './Modules/mOutbound/PickList/BatchPickList',
            params: '/:id',
            isActive: true

        },
        {
            id: '128',
            path: '/Inventory/ReservedStockReport',
            name: 'list',
            fileLocation: './Modules/mInventory/ReserveStockReport/ReserveStockReport',
            isActive: true

        },
        {
            id: '130',
            path: '/Reports/Inventory/StockValueReport',
            name: 'list',
            fileLocation: './Modules/mReports/StockValueReport/StockValueReport',
            isActive: true
        },
        {
            id: '131',
            path: '/Reports/DynamicReport/DynamicReport',
            name: 'list',
            fileLocation: './Modules/mReports/DynamicReport/DynamicReport',
            isActive: true
        },
        {
            id: '132',
            path: '/Reports/TPL/WHStockInOut',
            name: 'list',
            fileLocation: './Modules/mReports/WHStockInOut/WHStockInOut',
            isActive: true
        },
        {
            id: '133',
            path: '/Reports/Inventory/AverageCostOfInventory',
            name: 'list',
            fileLocation: './Modules/mReports/AverageCostOfInventory/AverageCostOfInventory',
            isActive: true
        },
        {
            id: '134',
            path: '/Reports/Inventory/WeightedAverageCostInventory',
            name: 'list',
            fileLocation: './Modules/mReports/WeightedAverageCostInventory/WeightedAverageCostInventory',
            isActive: true
        },
        {
            id: '135',
            path: '/Reports/Inventory/SoldInventoryValue',
            name: 'list',
            fileLocation: './Modules/mReports/SoldInventoryValue/SoldInventoryValue',
            isActive: true
        },
        {
            id: '136',
            path: '/Outbound/ProductionOrderImport',
            name: 'list',
            fileLocation: './Modules/mOutbound/ProductionOrderImport/ProductionOrderImport',
            isActive: true
        },
        {
            id: '138',
            path: '/Reports/Inbound/GateEntryReport',
            name: 'list',
            fileLocation: './Modules/mReports/GateEntryReport/GateEntryReport',
            isActive: true
        },
        {
            id: '139',
            path: '/Reports/TPL/TrialInvoice',
            name: 'list',
            fileLocation: './Modules/TPL/ThreePLInvoice/TrialInvoice',
            isActive: true,
        },
        {
            id: '140',
            path: '/Reports/TPL/ThreePLInvoice',
            name: 'list',
            fileLocation: './Modules/TPL/ThreePLInvoice/ThreePLInvoice',
            isActive: true,
            params: '/:TID/:FDATE/:TDATE'
        },
    ]
}
export const RouterData = new RouterDataStore();
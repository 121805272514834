import { Menu, Tooltip } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';
import React from "react";
// import { LoadGenericStoreCreation } from "../Drawer/DrawerStore.store";
import "./../../../Css/navbar.css";
import { Link, NavLink } from "react-router-dom";
//import Logo from "./../../../Assets/Images/MerlinWMS_logo_SVG-White.svg";
import MasterData from "./../../../Assets/Images/MasterData.png";
import Home from "./../../../Assets/Images/home.png";
import orders from "./../../../Assets/Images/orders.png";
import inbound from "./../../../Assets/Images/inbound.png";
import outbound from "./../../../Assets/Images/outbound.png";
import inventory from "./../../../Assets/Images/inventory.png";
import TPL from "./../../../Assets/Images/3PL.png";
import reports from "./../../../Assets/Images/reports.png";
import Adminstration from "./../../../Assets/Images/Adminstration.png";
import Dashboards from "./../../../Assets/Images/Dashboards.png";
import { observer } from "mobx-react";
import { LoginStoreCreation } from "../Login/LoginStore.store";
// import { DrawerExtendedItems,LoadGenericMenuServiceEntityObj } from './DrawerUtils'
import { UserInfoEntity } from "../Login/LoginUtils";
import Sider from "antd/lib/layout/Sider";
import { END_POINT_S3_Bucket } from './../../../Auth/Api/Endpoint';
import { reactLocalStorage } from "reactjs-localstorage";
// import Squbiqlogo from "../../../Assets/Images/logo_skubiq.svg"
interface Idata{
  openKeys: any ;
items_set:any
}

const { SubMenu } = Menu;

// submenu keys of first level

@observer
class Drawer extends React.PureComponent<any , Idata> {
  rootSubmenuKeys = ['77','74','2','3','5','7230','6','6191','7264' ];
  handleClick = () => {
    ////console.log("click ");
  };

  LoadUserInfoEntityObj: any = [];
  UserInfo: UserInfoEntity;
  LoadGenericOperatorReturns: any;
  constructor(props: any) {
    super(props);

    const { jwtToken, LoadGenericOperatorReturns } = LoginStoreCreation;
    this.UserInfo = JSON.parse(jwtToken);
    this.LoadGenericOperatorReturns = LoadGenericOperatorReturns;
    this.state = {
      openKeys: [],
      items_set: [],
    };
    // this.Service = new Service();
  }

  async componentDidMount() {
    ////debugger;
    document.title ="FalconWMS - Dashboard";
    if (this.LoadGenericOperatorReturns.length === 0) {
      var langugeEntity = {
        languageCode : 'en-us',
        SSOUserID : this.UserInfo.SSOUserID 
      }
      LoginStoreCreation.LoadGenericrDataStoreByLang(langugeEntity)
      setTimeout(() => {
        reactLocalStorage.set('lang','en-us')
      },2000)
    }

  }
  onOpenChange = (keys: any) => {
    ////debugger;
    // if(this.state.openKeys.length){
    //   var index = this.state.openKeys.findIndex(keys[0])
    // }

    var array = this.state.openKeys;
    ////console.log(this.state.openKeys);
    
    const latestOpenKey = keys.find((key: any) => array.indexOf(key) === -1 );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1 )  {
      keys.splice(1);
      keys.push(latestOpenKey)
      this.setState({ openKeys: keys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  };

  public render() {
    ////debugger;
    const { jwtToken, LoadGenericOperatorReturns,collapsed } = LoginStoreCreation;
    this.UserInfo = JSON.parse(jwtToken);
    const  { LogoPath ,iconePath} = this.UserInfo
    let IconPath = END_POINT_S3_Bucket + '' + iconePath;
    let Path=END_POINT_S3_Bucket + '' + LogoPath
    return (
      <>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Menu
          onClick={this.handleClick}
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          className="menu_link"
          mode="inline"
          key="Menu"
          inlineCollapsed={collapsed}
        >
          <div className="logo">
          <NavLink to="/">
          <div className="BIGSCREEN">
            { collapsed ?  
              iconePath !== '' ? <img src={IconPath} alt="logo" /> : <></> :
              LogoPath !== '' ? <img src={Path} alt="logo" /> : <></>
            }
          </div>
          <div className="SMALLSCREEN">
            { iconePath !== "" ? <img src={IconPath} alt="logo" /> : <></> }
          </div>
          </NavLink>
          </div>
          {LoadGenericOperatorReturns.map(item => {
            if (item.ParentMenuID === null) {
              ////debugger;
              let img = "";
              if (item.IconFileName === "MasterData.png") {
                img = MasterData;
              } 
              else if (item.IconFileName === "ORDERS.png") {
                img = orders;
              } 
              else if (item.IconFileName === "inbound.png") {
                img = inbound;
              } 
              else if (item.IconFileName === "outbound.png") {
                img = outbound;
              } 
              else if (item.IconFileName === "inventory.png") {
                img = inventory;
              } 
              else if (item.IconFileName === "3PL.png") {
                img = TPL;
              } 
              else if (item.IconFileName === "reports.png") {
                img = reports;
              } 
              else if (item.IconFileName === "adm.png") {
                img = Adminstration;
              } 
              else if (item.IconFileName === "Dashboards.png") {
                img = Dashboards;
              } 
              else if (item.IconFileName === "home.png") {
                img = Home;
              }
              return (
                <>
                  <SubMenu
                    key={item.MenuID}
                    title={
                      <span className={collapsed ? "Icons1" : "Icons"} >
                        <img src={img} alt=".." />
                        <span className={`${collapsed ? "none" : ""} mastermenu`} >
                        {item.LMenuText}
                        </span>
                      </span>
                    }
                  >
                    {LoadGenericOperatorReturns.map((subitem) => {
                      if (subitem.ParentMenuID === item.MenuID) {
                        let IsExtended = false;
                        LoadGenericOperatorReturns.map((extendedItem:any) => {
                          if (subitem.MenuID === extendedItem.ParentMenuID) {
                            IsExtended = true;
                            return(<></>)
                          }
                          else {
                            return(<></>)
                          }
                        })
                        if (IsExtended) {
                          ////console.log()
                          return (
                            //
                            <SubMenu
                              key={subitem.LMenuText}
                              title={subitem.LMenuText}
                              className='SubMain'
                            >
                              {LoadGenericOperatorReturns.map((extended_items :any) => {
                                  if (subitem.MenuID ===extended_items.ParentMenuID) {
                                    let Link1 = extended_items.Link;
                                    ////debugger
                                    return (
                                      <Menu.Item  className={`${collapsed ? "none11" : ""} SubSub`}>
                                        <Tooltip placement="right" title={extended_items.LMenuText}>
                                          <Link to={`/${Link1}`} key={extended_items.DisplayMenuId} className='SubMain' >
                                            <span>
                                              {extended_items.LMenuText}
                                            </span>
                                          </Link>
                                        </Tooltip>
                                      </Menu.Item>
                                    );
                                  }
                                  else {
                                    return(<></>)
                                  }
                                }
                              )}
                            </SubMenu>
                          );
                        } else {
                          ////debugger
                          let Link2 = subitem.Link;
                          ////console.log(subitem.Link)
                          return (
                            <Menu.Item key={subitem.DisplayMenuId}>
                              <Link to={`/${Link2}`} key={subitem.LMenuText}>
                                <span key={subitem.LMenuText}>{subitem.LMenuText}</span>
                              </Link>
                            </Menu.Item>
                          );
                        }
                      }
                      else {
                        return(<></>)
                      }
                    })}
                  </SubMenu>
                </>
              );
            }
            else {
                return(<></>)
            }
          })}
            {/* <div style={{position:"absolute", bottom:"0",textAlign:"center",padding:"5px 0px"}}>
                 <img src={Squbiqlogo} alt="logo" style={{width:"85%"}} />
          </div> */}

        </Menu>
        </Sider>
      </>
    );
  }
}

export default Drawer;

import React from "react";
import { ILogin } from "./LoginUtils";
import "./../../../Css/Login.css";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Logo from "./../../../Assets/Images/FalconWMS_logo.png";
import Carousel1 from "./../../../Assets/Images/img1.jpg";
import Carousel2 from "./../../../Assets/Images/Warehouse.jpg";
import Carousel3 from "./../../../Assets/Images/img4.jpg";
import { observer } from "mobx-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { TokenEntity } from './Entity/TokenEntity'
import { CallTokenService, CallUserInfo } from "./TokenService";
import { LoginStoreCreation } from "./LoginStore.store";
import ErrorModal from "../../../common/ErrorModal/ErrorModal";
import LoginCaptch from "./LoginCaptch";
import StickyText from "../../../common/StickyText/StickyText";
import { SpinnerLogin } from "../../../common/Spinner/SpinnerLogin";
import { reactLocalStorage } from "reactjs-localstorage";

// function encrypt(text:any, key:any) {
//   var crypto = require('crypto');
//   var alg = 'des-ede-cbc';
//   var key1 = new Buffer(key, 'utf-8');
//   var iv = new Buffer('QUJDREVGR0g=', 'base64');    //This is from c# cipher iv

//   var cipher = crypto.createCipheriv(alg, key1, iv);
//   var encoded = cipher.update(text, 'ascii', 'base64');
//   encoded += cipher.final('base64');

//   return encoded;
// }

// const CryptoJS = require("crypto-js");

// function decrypt(text: any, key: any) {
//   var bytes = CryptoJS.AES.decrypt(text, 'my-secret-key@123');
//   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// }

@observer
export default class Login extends React.Component<any, ILogin> {
  child: any;
  LoginEntityObject: any = [];
  LoginEntityObject1: any = [];
  constructor(props: any) {
    super(props);
    //this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.child = React.createRef();
    this.state = {
      username: "",
      password: "",
      ErrorMessage: false,
      sticky_show: false,
      sticky_type: '',
      sticky_title: '',
      sticky_message: '',
    };
  }
  public handleChange = (e: any) => {
    const re = /^[A-Za-z0-9][A-Za-z0-9_@./#&+-,*,%,$,!,^,(,)]*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };


  getDatafromChild(val: boolean) {
    this.setState({ sticky_show: val })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  handleFormSubmit = async (e: any) => {
    let val = this.child.current.capchavalidation()
    if (val === false) {
      e.preventDefault();
      this.stickyText(true, 'error', 'Error', 'Please enter valid captcha');
    }
    else {
      e.preventDefault();
      let TokenEntity: TokenEntity = {
        username: this.state.username,
        password: this.state.password,
        grant_type: "password",
        scope: "openid offline_access skubiq"
      }
      CallTokenService(TokenEntity);
    }
  };
  ForgetPassword() {
    LoginStoreCreation.ForgetPasswordStore(true)
  }
  componentDidMount() {
    document.title = "FalconWMS - Login Page";
    // var securityKey = 'my-secret-key@123';
    var URL = window.location.pathname;
    try {
      if (URL === "/login") {
        reactLocalStorage.clear();
        var URL1 = window.location.href;
        var data = window.decodeURI(window.location.href);
        var myArray = URL1.split('login?');
        var params = myArray.length ? myArray[1] : ""
        if (params !== undefined && params.length !== 0) {
          let tokendata = JSON.parse(data.split('login?')[1].substring(3))
          if (data.split('login?')[1].substring(0, 3) === "p1=" && data.split('login?')[1].substring(3).length) {
            reactLocalStorage.set('user', JSON.stringify(tokendata));
            CallUserInfo()
          }
          else window.location.href = "/"
        }

      }
    }
    catch {
      window.location.href = "/"
    }
  }
  public render() {
    const { jwtToken, errormessage, jwtTokenError } = LoginStoreCreation;
    return (
      <>
        <SpinnerLogin />
        <>
          {
            this.state.sticky_show ?
              <StickyText
                display={this.state.sticky_show}
                sticky_type={this.state.sticky_type}
                sticky_title={this.state.sticky_title}
                sticky_message={this.state.sticky_message}
                sendData={this.getDatafromChild}
                key={this.state.sticky_title}
              />
              :
              <></>
          }
        </>
        <div className="Main_login">
          {errormessage === true ?
            <ErrorModal /> : ""}
          <div className="coursel">
            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={true}
              showThumbs={false}
              useKeyboardArrows={false}
            >
              <div>
                <img src={Carousel1} height="465px" alt="" />
              </div>
              <div className="imgg">
                <img src={Carousel2} height="465px" alt="" />
              </div>
              <div className="imgg">
                <img src={Carousel3} height="465px" alt="" />
              </div>
            </Carousel>
          </div>
          <div className="Login_body">
            <div className="Image_title">
              <img src={Logo} height="60px" alt="logo" />
            </div>
            <h1>Start Tracking Your Products Here</h1>
            <form onSubmit={this.handleFormSubmit}>
              <div className="Login_Form">
                <div className="divFlex">
                  <Input required
                    placeholder="Email"
                    className="Email"
                    autoComplete="off"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="divFlex">
                  <Input.Password required
                    placeholder="Password"
                    className="Password"
                    autoComplete="off"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    iconRender={(visible: any) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                <br />
                <LoginCaptch ref={this.child} />
                <div className="sign_in_out_buttons">
                  <button className="Sign_in">SIGN-IN</button>
                </div>
                {
                  jwtToken === 'Invalid' ? <p className="Invalid">{jwtTokenError}</p> : ""
                }
                <div className="remember_forget">
                  <div>
                  </div>
                  <span style={{ cursor: "pointer" }} onClick={() => this.ForgetPassword()}>Forgot Password</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
import { Component } from "react";
import "./../../../Css/Login.css";
import { EyeFilled } from "@ant-design/icons";
import { observer } from "mobx-react";
import { LoginService } from "./loginService.service";
import StickyText from "../../../common/StickyText/StickyText";
import { Col, Row } from "antd";
import Logo from "./../../../Assets/Images/logo_skubiq.svg";
import { LoginStoreCreation } from "./LoginStore.store";
import { SpinnerLogin } from "../../../common/Spinner/SpinnerLogin";

interface IChangePassword {
    NewPassword: string,
    ReNewPassword: string
    sticky_show: boolean,
    sticky_type: string,
    sticky_title: string,
    sticky_message: string,
    passwordShown1: boolean
    passwordShown2: boolean
}
@observer
class changePassword extends Component<any, IChangePassword> {
    ForgetPasswordEntityObject: any = [];
    LoginServiceObj: LoginService;
    constructor(props: any) {
        super(props);
        this.LoginServiceObj = new LoginService();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            NewPassword: '',
            ReNewPassword: '',
            passwordShown1: false,
            passwordShown2: false,
            sticky_show: false,
            sticky_type: '',
            sticky_title: '',
            sticky_message: '',
        };
    }
    stickyText(show: boolean, type: string, title: string, message: string) {
        this.setState({
            sticky_show: show,
            sticky_type: type,
            sticky_title: title,
            sticky_message: message
        })
        return false;
    }
    getDatafromChild(val: boolean) {
        this.setState({ sticky_show: val })
    }
    togglePassword1 = () => {
        setTimeout(() => {
            this.setState({
                passwordShown1: !this.state.passwordShown1, sticky_show: false
            })
        }, 2000);
        this.setState({
            passwordShown1: !this.state.passwordShown1, sticky_show: false
        })
    }
    togglePassword2 = () => {
        setTimeout(() => {
            this.setState({
                passwordShown2: !this.state.passwordShown2, sticky_show: false
            })
        }, 2000);
        this.setState({
            passwordShown2: !this.state.passwordShown2, sticky_show: false
        })
    }

    resetPassword = async () => {
        //debugger
        if (this.state.NewPassword === "") {
            this.stickyText(true, 'error', 'error', 'Please enter password');
            return false
        }
        var regularExpretion = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (this.state.NewPassword.length < 6 || this.state.NewPassword.length > 16) {
            return this.stickyText(true, 'error', 'Error', 'Weak password! Please enter more than 6 Char');
        }
        if (!regularExpretion.test(this.state.NewPassword)) {
            return this.stickyText(true, 'error', 'Error', 'Password must contain at least 8 characters, including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character(!@#$%^&*)');
        }
        if (this.state.ReNewPassword === "") {
            this.stickyText(true, 'error', 'error', 'Please enter confirm password');
            return false
        }
        if (this.state.ReNewPassword !== this.state.NewPassword) {
            this.stickyText(true, 'error', 'error', 'Password and confirm password are does not match.');
            return false
        }
        
        var params2 = "";
        var URL = window.location.href;
        if (URL.length) {
            var myArray = URL.split('changepassword?');
            var params = myArray.length ? myArray[1] : ""
            var params1 = params.split('Email=');
            params2 = params1[1]
        }

        this.ForgetPasswordEntityObject =
        {
            "Email": params2,
            "Password": this.state.NewPassword,
        };
        const res: any = await this.LoginServiceObj.ChangePasswordService(this.ForgetPasswordEntityObject);
        //debugger
        if (res.status === 200) {
            var GetData = res.data.entityObject.result;
            GetData = GetData ? JSON.parse(res.data.entityObject.result) : 0;
            var ID = GetData ? GetData[0].N : 0;
            if (ID === 1) {
                this.stickyText(true, 'success', 'sucess', 'Password Changed Successfully');
                setTimeout(() => {
                    this.setState({ sticky_show: false })
                    window.location.href = "/";
                }, 3000)
            }
        }
    }
    LoginAgain () {
        LoginStoreCreation.ForgetPasswordStore(false)
        window.location.href= '/'
    }

    public handleChange = (e: any) => {
        //debugger
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
    }

    render() {
        return (
            <div className="loginpagebg">
                <SpinnerLogin />
                <div className="container changepassword">
                    <div className='stikey'>
                        <>
                            {
                                this.state.sticky_show ?
                                    <StickyText
                                        display={this.state.sticky_show}
                                        sticky_type={this.state.sticky_type}
                                        sticky_title={this.state.sticky_title}
                                        sticky_message={this.state.sticky_message}
                                        sendData={this.getDatafromChild}
                                        key={this.state.sticky_title}
                                    />
                                    :
                                    <></>
                            }
                        </>
                    </div>
                    <span className="homestyle">
                        {/* <img src={Home_Icon_WH} alt="Home_Icon_WH" /> */}
                    </span>
                    <div className="changepassword1">
                        <div className="title">
                            <img src={Logo} style={{ marginBottom: "0px" }} alt="" />
                        </div>
                        <form action="#">
                            <div className="title">Reset Password</div>
                            <Row gutter={16} className="inputRow">
                                <Col span={24} className='input-box'>
                                    <div className="divFlex">
                                        <input
                                            type={this.state.passwordShown1 ? "text" : "password"}
                                            required
                                            name="NewPassword"
                                            maxLength={20}
                                            value={this.state.NewPassword}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                        <EyeFilled onClick={() => this.togglePassword1()} />
                                        <label>Password</label>
                                        <span className="required-label"></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16} className="inputRow">
                                <Col span={24}>
                                    <div className="divFlex">
                                        <input
                                            type={this.state.passwordShown2 ? "text" : "password"}
                                            required
                                            name="ReNewPassword"
                                            maxLength={20}
                                            value={this.state.ReNewPassword}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                        <EyeFilled onClick={() => this.togglePassword2()} />
                                        <label>Confirm Password</label>
                                        <span className="required-label"></span>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                        </form>
                        <div className="sign_in_out_buttons">
                            <button className="Sign_in" onClick={this.resetPassword}>Reset Password</button>
                        </div>
                        <div className="remember_forget">
                            <span style={{ cursor: "pointer" }} onClick={()=> this.LoginAgain()} >Back to Login</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default changePassword;

import { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import { HomeOutlined , LogoutOutlined} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { LogInOutService } from '../../Modules/General/Login/LogAudit';
// import { CloseOutlined } from '@ant-design/icons';
interface IState {
    display: boolean;
}

@observer
class ErrorModalURL extends Component<any,  IState> {
    constructor(props: any) {
        super(props)
        this.state = {
            display:true,
        }
    }
    Home = () => {
        //debugger
        setTimeout(() => {
            window.location.href = "/";
        },500)
    }
    Logout = async () => {
        await LogInOutService(2);
        setTimeout(() => {
          window.localStorage.clear();
          window.location.href = "/";
        },500)
      }
    render() {
        return (
            <div>
                <Modal
                    visible={this.state.display}
                    title= "Error"
                    className = 'ErrorModal ErrorURL'
                    // onCancel={() => this.handleCancel()}
                    footer={[
                       <></>
                    ]}
                >   
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <div className="divFlex">
                                <p>Unauthenticated URL </p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row button-alignment" span={24}>
                        <Button key="Close" className='OkBtn' onClick={() => this.Home()}>
                            Home <HomeOutlined />
                        </Button>
                        <Button key="Close" className='OkBtn' onClick={() => this.Logout()}>
                            Logout <LogoutOutlined />
                        </Button>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

export default ErrorModalURL;
//import React from "react";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { HomeOutlined, CaretRightOutlined,} from '@ant-design/icons';

const Breadcrumbs = ({ breadcrumbs }) => (
  <>
  <div className="breadcrumbs NavBar">
    <HomeOutlined />
    {
      breadcrumbs.map(({ breadcrumb, match }, index) => (
      <div className="breadcrumb_block" key={match.url}>
        {
          match.url === "/" ? 
          <>
            <Link to={match.url || ""}>{isNaN(parseInt(breadcrumb.props.children)) === false ? "" : breadcrumb}</Link>
          </>
          :
          <>{isNaN(parseInt(breadcrumb.props.children)) === false ? "" : breadcrumb}</>
        }
        {/* <Link to={match.url || ""}>{isNaN(parseInt(breadcrumb.props.children)) === false ? "" : breadcrumb}</Link> */}
        {isNaN(parseInt(breadcrumb.props.children)) === false ? "" : index < breadcrumbs.length - 1 && <CaretRightOutlined />}
      </div>
    ))}
  </div>
  </>
);

export default withBreadcrumbs()(Breadcrumbs);
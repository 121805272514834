export const EndPoints = {
    END_POINT_SSO :'https://identity.skubiq.com/',
    END_POINT_SKUBIQ_SECURITY :'https://security.skubiq.com/',
    END_POINT_MasterData : 'https://master.skubiq.com/',
    END_POINT_Orders :'https://orders.skubiq.com/',
    END_POINT_Inbound :'https://inbound.skubiq.com/',
    END_POINT_Inventory :'https://inventory.skubiq.com/',
    END_POINT_Outbound :'https://outbound.skubiq.com/',
    END_POINT_3PL :'https://3pl.skubiq.com/',
    END_POINT_ADMINSTRATION : 'https://administration.skubiq.com/',
    END_POINT_REPORTS : 'https://reports.skubiq.com/',
    END_POINT_S3_Bucket : 'https://inventraxpostprod.s3.ap-south-1.amazonaws.com/',
    JSPrintMngrScript : 'https://jsprint.skubiq.com/JSPrintManager.js',
    PrintFunctionScript : 'https://jsprint.skubiq.com/PrintFunction.js',
    FlipKart_Redirect_URL : 'https://192.168.1.69:44331/ECommerceAggregator/FlipcartAccessToken'
}
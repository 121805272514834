import { TokenEntity } from "./Entity/TokenEntity";
import { LoginStoreCreation } from "./LoginStore.store";


export function CallTokenService (TokenEntity:TokenEntity) {
    ////debugger
    var bodyFormData = new FormData();
    bodyFormData.append('username', TokenEntity.username); 
    bodyFormData.append('password', TokenEntity.password); 
    bodyFormData.append('grant_type',TokenEntity.grant_type); 
    bodyFormData.append('scope', TokenEntity.scope); 
    //bodyFormData.append('refresh_token', TokenEntity.refresh_token ?TokenEntity.refresh_token:""); 
    LoginStoreCreation.getLoginStore(bodyFormData);
};
export function CallUserInfo(){
    LoginStoreCreation.LoadLoginStoreFromLocalStorage()
    LoginStoreCreation.GetUserData();
    LoginStoreCreation.GetLanguages();
}

export function RefreshCallTokenService (TokenEntity:TokenEntity) {
    ////debugger
    var bodyFormData = new FormData();
    bodyFormData.append('username', TokenEntity.username); 
    bodyFormData.append('password', TokenEntity.password); 
    bodyFormData.append('grant_type',TokenEntity.grant_type); 
    bodyFormData.append('scope', TokenEntity.scope); 
    bodyFormData.append('refresh_token', TokenEntity.refresh_token ?TokenEntity.refresh_token:""); 
    LoginStoreCreation.getLoginStore(bodyFormData);
};

import { Component } from 'react';
import { observer } from 'mobx-react';
import { LoginStoreCreation } from '../../Modules/General/Login/LoginStore.store';
import StickyText from '../StickyText/StickyText';

interface IState {
  sticky_show: boolean,
  sticky_message: string,
  sticky_type: string,
  sticky_title: string,
}

@observer
class ErrorModal extends Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
    }
  }
  handleCancel = () => {
    LoginStoreCreation.DBError(false, '');
  }
  getDataFromSticky(show: boolean) {
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  render() {
    const { errormessage, ServerErrorMessage } = LoginStoreCreation;
    return (
      errormessage ?
        <StickyText
          display={errormessage}
          sticky_type='error'
          sticky_title=''
          sticky_message={ServerErrorMessage}
          sendData={this.getDataFromSticky}
          key={ServerErrorMessage}
        />
        :
        <></>

    );
  }
}
export default ErrorModal;
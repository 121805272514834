import Axios from "axios";
import { LoginStoreCreation } from "../Modules/General/Login/LoginStore.store";
import { RefreshCallTokenService } from "../Modules/General/Login/TokenService";
import { EnvironmentValiables } from './../Auth/Api/Env';
import { TokenEntity } from "../../src/Modules/General/Login/Entity/TokenEntity";
import StickyText  from './../common/StickyText/StickyText';
export class Service {
    post(BaseUrl: any, url?: string, EntityObject?: any) {
        const {  UserInfo , jwtToken } = LoginStoreCreation;
        //debugger
        return new Promise((resolve, reject) =>{
            LoginStoreCreation.SpinLoading(true);
            this.stickyText(false,'');
            try{
                var basicAuth = 'Bearer '+JSON.parse(UserInfo).access_token;
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: {
                        'Authorization':  basicAuth,
                        'x-xss-protection':'1; mode=block',
                        'referrer-policy':'same-origin',
                        'Strict-Transport-Security':'max-age=31536000; includeSubDomains; preload',
                        'X-Frame-Options':'SAMEORIGIN',
                        'Content-Security-Policy':'default-src \'none\'; img-src \'self\'; script-src \'self\'; style-src \'self\'; object-src \'none\'; frame-ancestors \'none\''
                    },
                    data:
                    {
                        "auth": {
                            "loggedInUserID": "string",
                            "transactionUserID": "string",
                            "authToken": "string"
                        },
                        EntityObject,
                        "messages": [
                            {
                                "merlinWMSMessage": "string",
                                "merlinWMSExceptionCode": "string",
                                "uniqueErrorCode": "string",
                                "userMessage": true
                            }
                        ],
                        "isError": true
                    }
                }).then(res => {
                    //debugger
                    LoginStoreCreation.SpinLoading(false)
                    resolve(res);
                }).catch((error) => {
                    //debugger
                    LoginStoreCreation.SpinLoading(false)
                    if(error.response !== undefined && ( error.response.status === 401 || error.response.status === 403 )){
                        // window.localStorage.clear();
                        // window.location.href = "/";
                        let token = JSON.parse(jwtToken)
                        let TokenEntity:TokenEntity = {
                            username:token.email,
                            password:atob(token.Password),
                            grant_type:"refresh_token",
                            scope:"openid offline_access skubiq",
                            refresh_token: JSON.parse(UserInfo).refresh_token
                        }
                        RefreshCallTokenService(TokenEntity) 
                    }
                    else {
                        console.log(error?.response?.data);
                        this.stickyText(true, 'Service Unavailable');
                        reject(error);
                    }
                });
            } catch (error) {
                this.stickyText(true, 'Service Unavailable');
                reject(error);
            }
        });
    }
    profileupdate(BaseUrl: any, url?: string, EntityObject?: any) {
        //const {  UserInfo  } = LoginStoreCreation;
        const {  UserInfo , spin_loading,jwtToken } = LoginStoreCreation;
        this.stickyText(false,'');
        //debugger
        return new Promise((resolve, reject) =>{
            if(!spin_loading)  LoginStoreCreation.SpinLoading(true);
            try{
                var basicAuth = 'Bearer '+JSON.parse(UserInfo).access_token;
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: {
                        'Authorization':  basicAuth,
                        'x-xss-protection':'1; mode=block',
                        'referrer-policy':'same-origin',
                        'Strict-Transport-Security':'max-age=31536000; includeSubDomains; preload',
                        'X-Frame-Options':'SAMEORIGIN',
                        'Content-Security-Policy':'default-src \'none\'; img-src \'self\'; script-src \'self\'; style-src \'self\'; object-src \'none\'; frame-ancestors \'none\''
                    },
                    data:EntityObject,
                        
                }).then(res => {
                    //debugger
                    if(!spin_loading)  LoginStoreCreation.SpinLoading(false)
                    resolve(res);
                    //console.log(res);
                }).catch((error) => {
                    //debugger
                    if(!spin_loading)  LoginStoreCreation.SpinLoading(false)
                    if( error.response === undefined ? true : (error.response.status === 401 || error.response.status === 403 )){
                        let token = JSON.parse(jwtToken)
                        let TokenEntity:TokenEntity = {
                            username:token.email,
                            password:atob(token.Password),
                            grant_type:"refresh_token",
                            scope:"openid offline_access skubiq",
                            refresh_token: JSON.parse(UserInfo).refresh_token
                        }
                        RefreshCallTokenService(TokenEntity) 
                    }
                    else {
                        console.log(error?.response?.data);
                        this.stickyText(true, 'Service Unavailable');
                        reject(error);
                    }
                });
            } catch (error) {
                this.stickyText(true, 'Service Unavailable');
                reject(error);
            }
        });
    }
    autocomplete(BaseUrl: any, url?: string, EntityObject?: any) {
        const {  UserInfo, jwtToken  } = LoginStoreCreation;
        ////debugger
        this.stickyText(false,'');
        return new Promise((resolve, reject) =>{
            try{
                var basicAuth = 'Bearer '+JSON.parse(UserInfo).access_token;
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: {
                        'Authorization':  basicAuth,
                        'x-xss-protection':'1; mode=block',
                        'referrer-policy':'same-origin',
                        'Strict-Transport-Security':'max-age=31536000; includeSubDomains; preload',
                        'X-Frame-Options':'SAMEORIGIN',
                        'Content-Security-Policy':'default-src \'none\'; img-src \'self\'; script-src \'self\'; style-src \'self\'; object-src \'none\'; frame-ancestors \'none\''
                    },
                    data:
                    {
                        "auth": {
                            "loggedInUserID": "string",
                            "transactionUserID": "string",
                            "authToken": "string"
                        },
                        EntityObject,
                        "messages": [
                            {
                                "merlinWMSMessage": "string",
                                "merlinWMSExceptionCode": "string",
                                "uniqueErrorCode": "string",
                                "userMessage": true
                            }
                        ],
                        "isError": true
                    }
                }).then(res => {
                    resolve(res);
                }).catch((error) => {
                    //debugger
                    if(error.response !== undefined && ( error.response.status === 401 || error.response.status === 403 )){
                        let token = JSON.parse(jwtToken)
                        let TokenEntity:TokenEntity = {
                            username:token.email,
                            password:atob(token.Password),
                            grant_type:"refresh_token",
                            scope:"openid offline_access skubiq",
                            refresh_token: JSON.parse(UserInfo).refresh_token
                        }
                        RefreshCallTokenService(TokenEntity) 
                    }
                    else {
                        console.log(error?.response?.data);
                        this.stickyText(true, 'Service Unavailable');
                        reject(error);
                    }
                });
            } catch (error) {
                this.stickyText(true, 'Service Unavailable');
                reject(error);
            }
        });
    }
    stickyText(bool:any,statusText:any) {
        LoginStoreCreation.DBError(bool,statusText)
    }
    login(BaseUrl: string, url: string, EntityObject?: any) {
        //debugger
        const { spin_loading} = LoginStoreCreation;
        if(!spin_loading)  LoginStoreCreation.SpinLoading(true)
        return new Promise((resolve, reject) => {
            //debugger
            try {
                var ClientID = EnvironmentValiables.ClientID;
                var Client_Secret = EnvironmentValiables.Client_Secret;
                var basicAuth = 'Basic ' + btoa(ClientID + ':' + Client_Secret);     
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        'Authorization':  basicAuth
                    },
                    data: EntityObject
                }).then(res => {
                    ////debugger                    
                    if(!spin_loading)  LoginStoreCreation.SpinLoading(false)
                    resolve(res);
                }).catch((error) => {
                    //debugger
                    LoginStoreCreation.Invalid(error.response === undefined ? "Please Contact Skubiq Team" : error.response.data ? error.response.data.error_description : "Invalid")                 
                    if(!spin_loading)  LoginStoreCreation.SpinLoading(false)
                    resolve("error");
                });
            } catch (error) {
                resolve("error");
            }
        });
    }

    get(BaseUrl: any, url?: string, data?: any) {
        return new Promise((resolve, reject) => {
            //debugger
            try {
                Axios({
                    method: 'get',
                    url: `${BaseUrl}${url}`
                }).then(res => {
                     //debugger
                    resolve(res);
                }).catch((error) => {
                     //debugger
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });
    }
    forget_change_Password(BaseUrl: any, url?: string, EntityObject?: any) {
        const { spin_loading} = LoginStoreCreation;
        if(!spin_loading)  LoginStoreCreation.SpinLoading(true)
        return new Promise((resolve, reject) => {
            //debugger
            try {
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    data:
                    {
                        "auth": {
                            "loggedInUserID": "string",
                            "transactionUserID": "string",
                            "authToken": "string"
                        },
                        EntityObject,
                        "messages": [
                            {
                                "merlinWMSMessage": "string",
                                "merlinWMSExceptionCode": "string",
                                "uniqueErrorCode": "string",
                                "userMessage": true
                            }
                        ],
                        "isError": true
                    }
                }).then(res => {
                     //debugger
                     if(!spin_loading)  LoginStoreCreation.SpinLoading(false)
                    resolve(res);
                }).catch((error) => {
                     //debugger               
                    if(!spin_loading)  LoginStoreCreation.SpinLoading(false)
                    resolve("error");
                });
            } catch (error) {
                reject(error);
            }
        });
    }
    kitpost(BaseUrl: any, url?: string, EntityObject?: any) {
        const {  UserInfo , jwtToken } = LoginStoreCreation;
        //debugger
            this.stickyText(false,'');
        return new Promise((resolve, reject) =>{
            try{
                var basicAuth = 'Bearer '+JSON.parse(UserInfo).access_token;
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: {
                        'Authorization':  basicAuth,
                        'x-xss-protection':'1; mode=block',
                        'referrer-policy':'same-origin',
                        'Strict-Transport-Security':'max-age=31536000; includeSubDomains; preload',
                        'X-Frame-Options':'SAMEORIGIN',
                        'Content-Security-Policy':'default-src \'none\'; img-src \'self\'; script-src \'self\'; style-src \'self\'; object-src \'none\'; frame-ancestors \'none\''
                    },
                    data:
                    {
                        "auth": {
                            "loggedInUserID": "string",
                            "transactionUserID": "string",
                            "authToken": "string"
                        },
                        EntityObject,
                        "messages": [
                            {
                                "merlinWMSMessage": "string",
                                "merlinWMSExceptionCode": "string",
                                "uniqueErrorCode": "string",
                                "userMessage": true
                            }
                        ],
                        "isError": true
                    }
                }).then(res => {
                    resolve(res);
                }).catch((error) => {
                    //debugger
                    if( error.response === undefined ? true : (error.response.status === 401 || error.response.status === 403 )){
                        let token = JSON.parse(jwtToken)
                        let TokenEntity:TokenEntity = {
                            username:token.email,
                            password:atob(token.Password),
                            grant_type:"refresh_token",
                            scope:"openid offline_access skubiq",
                            refresh_token: JSON.parse(UserInfo).refresh_token
                        }
                        RefreshCallTokenService(TokenEntity) 
                    }
                    else if(error.response.status) {
                        console.log(error?.response?.data);
                        this.stickyText(true, 'Service Unavailable');
                        reject(error);
                    }
                });
            } catch (error) {
                this.stickyText(true, 'Service Unavailable');
                reject(error);
            }
        });
    }
}
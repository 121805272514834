import { action, makeObservable, observable } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";
import { LoginService } from "./loginService.service";
import { QueryString } from './CheckAuthorization'
import { LogInOutService } from "./LogAudit";

export class LoginStore extends LoginService {
    constructor() {
        super();
        makeObservable(this);
        ////debugger
    }
    
    @observable
    public jwtToken: string ="";

    @observable
    public jwtTokenError: string ="";
    
    @observable
    public spin_loading : boolean = false;

    @observable
    public errormessage : boolean = false;

    @observable
    public ServerErrorMessage : string = "Please contact skubiq support team";

    @observable
    public errorURL : boolean = false;
    
    @observable
    public LoadGenericOperatorReturns: any[] = [];

    @observable
    public Languages : any[] = [];

    @observable
    public jwtToken1tring = ""

    @observable
    public UserInfo : string ="";

    @observable
    public ForgetPasswordFlag : boolean = false;

    @observable
    public DarkMode : boolean = false;

    @observable
    public collapsed : boolean = false;

    @observable
    public SubscriptionAlert : string = ""
    
    @action('get jwt token from local storage')
    async LoadLoginStoreFromLocalStorage() {
        ////debugger
        const LocalStr:string =  reactLocalStorage.get('UserInfo');
        this.jwtToken=LocalStr;
        this.UserInfo = reactLocalStorage.get('user');
        var SidebarData :any = reactLocalStorage.get('sidebar')
        this.LoadGenericOperatorReturns = SidebarData ? JSON.parse(SidebarData) : [];
        this.DarkMode  =  reactLocalStorage.get('dark') ? JSON.parse(reactLocalStorage.get('dark')) :false
        const Languages1:any =  reactLocalStorage.get('LangData') ? JSON.parse(reactLocalStorage.get('LangData')) : [];
        this.Languages = Languages1;
        const SubscriptionAlert:any =  reactLocalStorage.get('SubscriptionAlert') ? JSON.parse(reactLocalStorage.get('SubscriptionAlert')) : [];
        this.SubscriptionAlert = SubscriptionAlert;
    }
    @action('get Login data')
    getLoginStore = async(LoginEntityObject: any) => {
        let res:any = await this.getloginService(LoginEntityObject)
        //debugger
            if (res.status === 200) {
                reactLocalStorage.set('user', JSON.stringify(res.data));
                this.UserInfo = JSON.stringify(res.data);
                await this.GetUserData();
                await this.GetLanguages();
            }
    }
    @action('Get User Data')
    async GetUserData() {
        //debugger
        let UserInfo: any = this.getUserInfoService("")
        UserInfo.then((UserData:any) => {
            if (UserData.status === 200) {
                let UserInfo = {
                    "SSOUserID": UserData.data.ssoUserID,
                    "UserID": UserData.data.userID,
                    "FirstName": UserData.data.firstName,
                    "LastName": UserData.data.lastName,
                    "email": UserData.data.email,
                    "TenantID": UserData.data.tenantID,
                    "TenantName": UserData.data.tenantName,
                    "AccountID": UserData.data.accountID,
                    "UserTypeID": UserData.data.userTypeID,
                    "Roles": UserData.data.roles,
                    "Warehouses": UserData.data.warehouses,
                    "Account": UserData.data.account,
                    "Password": UserData.data.enPassword,
                    "LogoPath": UserData.data.logopath,
                    "iconePath": UserData.data.iconPath,
                    "AppSSoAccountID":UserData.data.ssoAccountId
                }
                reactLocalStorage.set('UserInfo', JSON.stringify(UserInfo));
                this.jwtToken = JSON.stringify(UserInfo)
                LogInOutService(1);
                this.GetSubscriptionAlert(UserData.data.ssoAccountId);
                //return 1;
            }
            if (UserData.status === 204) {
                // alert('No content');
                this.jwtToken = "Invalid";
                this.jwtTokenError = "Account is inactive";
            }
        })
    }
    @action('Get Languages')
    async GetLanguages() {
        //debugger
        let LangEntityObject = {
            CountryID : 0
        }
        let Lang: any = this.getLanuagesService(LangEntityObject)
        Lang.then((LangData:any) => {
            //debugger
            if (LangData.status === 200) {
                var LangData1 = JSON.parse(LangData.data.entityObject.result).Table;
                reactLocalStorage.set('LangData', JSON.stringify(LangData1));
                this.Languages = LangData1
            }
        })
    }
    @action('Get LoadGeneric in page load')
    async LoadGenericrDataStore(LoadGenericMenuServiceEntityObj: any) {
       // //debugger
        const res: any = await this.LoadGenericService(LoadGenericMenuServiceEntityObj);
        if (res.status === 200) {
            reactLocalStorage.set('sidebar',JSON.stringify(res.data));
            this.LoadGenericOperatorReturns = res.data
        }
    }
    @action('Get LoadGeneric in page load by lang')
    async LoadGenericrDataStoreByLang(langugeEntity: any) {
       // //debugger
        const res: any = await this.LoadGenericByLangService(langugeEntity);
        //debugger
        if (res.status === 200) {
            var Sidebar = JSON.parse(res.data.entityObject.result).Table;
            reactLocalStorage.set('sidebar',JSON.stringify(Sidebar));
            this.LoadGenericOperatorReturns = Sidebar
        }
    }
    @action('GetSubscriptionAlert')
    async GetSubscriptionAlert(AccountID: any) {
        let GetSubscriptionAlert = {
            AccountID :AccountID.toString() //  "333"
        }
        const res: any = await this.GetSubscriptionAlertService(GetSubscriptionAlert);
        if(res.status === 200) {
            var message = res.data.length ? res.data[0]?.N : "";
            this.SubscriptionAlert = message === undefined || message === null ? "" : message;
            reactLocalStorage.set('SubscriptionAlert',JSON.stringify(this.SubscriptionAlert));
        }
    }
    @action('Forget Password')
    async ForgetPasswordStore(bool:boolean) {
        if(bool === true) {
            this.ForgetPasswordFlag = true;
        }
        else {
            this.ForgetPasswordFlag = false;
        }
    }
    // @action('Get GetUserInfoStore in page load')
    // async GetUserInfoStore(LoadUserInfoEntityObj: any) {
    //     ////debugger
    //     const res1: any = await this.getUserInfoService(LoadUserInfoEntityObj);
    //     // if (res1.status === 200) {
    //     //      reactLocalStorage.set('UserInfo',JSON.stringify(res1.data));
    //     //     this.UserInfo = res1.data
    //     //     return this.UserInfo
    //     // }
    //     if (res1.status === 200) {
    //         let UserInfo={
    //             "SSOUserID": res1.data.ssoUserID,
    //             "UserID": res1.data.userID,
    //             "FirstName": res1.data.firstName,
    //             "LastName": res1.data.lastName,
    //             "email": res1.data.email,
    //             "TenantID": res1.data.tenantID,
    //             "TenantName": res1.data.tenantName,
    //             "AccountID": res1.data.accountID,
    //             "UserTypeID": res1.data.userTypeID,
    //             "Roles": res1.data.roles,
    //             "Warehouses": res1.data.warehouses,
    //             "Account": res1.data.account,
    //             "Password": res1.data.enPassword
    //         }
    //         reactLocalStorage.set('UserInfo',JSON.stringify(UserInfo));
    //         this.jwtToken = JSON.stringify(UserInfo)
    //        // return this.UserInfo
    //     }
    // }
    @action('Invalid')
    async Invalid(error : string) {
       this.jwtToken = "Invalid"
       this.jwtTokenError = error === undefined ? "Please enter valid credentials" : error
    }
    @action('Spin Loading')
    async SpinLoading(bool:boolean) {
        if(bool) {
            this.spin_loading = true
        }
        else {
            this.spin_loading = false
        }
    }
    @action('error')
    async DBError(bool:boolean, statusText:any) {
        if(bool) {
            this.errormessage = true;
            this.ServerErrorMessage = statusText;
        }
        else {
            this.errormessage = false
            this.ServerErrorMessage = "Please contact skubiq support team";
        }
    }
    @action('errorURL')
    async ErrorURL(bool:boolean) {
        if(bool) {
            this.errorURL = true
        }
        else {
            this.errorURL = false
        }
    }

    @action('Check Authorization Store')
    async CheckAuthorizationStore(QueryString:QueryString) {
       ////debugger
       return await this.CheckAuthorizationService(QueryString);
    }
    @action('LogInOutService')
    async LogInOutStore(LogInOutEntity:any) {
       //debugger
       return await this.LogInOutService(LogInOutEntity);
    }
    @action('DarkMode')
    async DarkModeStore(DarkMode:boolean) {
       //debugger
       if(DarkMode) {
        this.DarkMode = true
       }
       else {
        this.DarkMode = false
       }
    }
    @action('MenuToggle')
    async MenuToggleStore(collapsed:boolean) {
       //debugger
       if(collapsed) {
        this.collapsed = false
       }
       else {
        this.collapsed = true
       }
    }

}



export const LoginStoreCreation = new LoginStore();



/// localstorage

// user --- store the user access token related data

// UserInfo -- after login if token is vaild get user info

// sidebar -- after login if token is vaild get user info



/// mobx stre

// UserInfo --- store the user access token related data

// jwtToken -- after login if token is vaild store user info

// LoadGenericOperatorReturns -- after login if token is vaild get user info

//import { Tooltip } from 'antd';
import { Tooltip } from 'antd';
import React, { Component } from 'react';
import './MerlinAutoComplete.css'
interface IKeyValueData {
  key: number,
  value: string
}
interface IProps {

  activeOption?: number,
  options: IKeyValueData[],
  onValueSelect?: (value: IKeyValueData[]) => void;
  AutocompleteOnChange?: (value: string) => void;
  defaultValue?: string | number | null,
  aPICallRequired?: boolean,
  prefix?: string,
  PrefixMinLenght: number,
  label?: string,
  disabled?: boolean
}
interface IState {

}
class Autocomplete extends Component<IProps, IState>
{

  state =
    {
      scrolldata: 0,
      activeOption: 0,
      placeholder: this.props.label,
      filteredOptions: this.props.options,
      showOptions: false,
      userInput: (this.props.defaultValue === null || this.props.defaultValue === undefined) ? '' : this.props.defaultValue,
      aPICallRequired: this.props.aPICallRequired,
      prefix: this.props.prefix,
      PrefixMinLenght: this.props.PrefixMinLenght
    };

  onChange = (e: any) => {
    ////debugger
    const { AutocompleteOnChange } = this.props;
    const { options, PrefixMinLenght } = this.props;
    const userInput = e.currentTarget.value;
    if (userInput.length >= PrefixMinLenght) {
      const filteredOptions = userInput === '' ? options : options.filter(
        (option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      this.setState({
        activeOption: 0,
        filteredOptions: filteredOptions,
        showOptions: true,
        userInput: e.currentTarget.value
      });
      if (AutocompleteOnChange) { AutocompleteOnChange(userInput) }
    }
    else {
      if (AutocompleteOnChange) { AutocompleteOnChange(userInput) }
      this.setState({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.value
      });
    }
  };
  onKeyDown = (e: any) => {

    const { onValueSelect } = this.props;
    let { activeOption, filteredOptions } = this.state;
    const { options } = this.props;
    if (e.keyCode === 13)  //ENTER
    {
      ////debugger
      if (filteredOptions.length === 0) {
        filteredOptions = this.props.options;
      }
      const filtered = options.filter(
        (option) => option.value.toLowerCase().indexOf(filteredOptions[activeOption].value.toLowerCase()) > -1
      );
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: options[activeOption].value,
        filteredOptions: []
      });
      if (onValueSelect) { onValueSelect(filtered) }
    }
    else if (e.keyCode === 38) {
      const scrollDemo: any = document.getElementById("scrollDemo");
      // const output: any = document.querySelector(".options");
      //debugger
      var count: any = this.state.scrolldata <= 0 ? 0 : Number(this.state.scrolldata) - 31
      scrollDemo.scrollTop = count
      this.setState({ scrolldata: count })
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });

    }
    else if (e.keyCode === 40) {
      //debugger
      // var data=20
      var optionslength = options[options.length - 1].key
      var activivoption = options[activeOption].key !== null || undefined ? options[activeOption].key : options[options.length - 1].key
      //  if(optionslength !==activivoption ){
      const scrollDemo: any = document.getElementById("scrollDemo");
      var countone: any = optionslength !== activivoption ? Number(this.state.scrolldata) + 31 : this.state.scrolldata
      scrollDemo.scrollTop = countone
      this.setState({ scrolldata: countone })
      // console.log(this.state.scrolldata)
      // console.log(activivoption)
      // console.log(this.state.scrolldata)
      // return false
      //  }
      // scrollDemo.scrollIntoView()
      // scrollDemo.addEventListener("scroll", (event: any) => {
      //     output.innerHTML = `scrollTop: ${data} `;
      // }, { passive: true });
      if (activeOption + 1 === filteredOptions.length) {
        return;
      } else {
        this.setState({ activeOption: optionslength !== activivoption ? activeOption + 1 : activeOption });
      }
    }
    else if (e.keyCode === 9)   //tab
    {
      this.setState({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.value
      });
    }
    // else if (e.keyCode === 9) 
    // {
    //     if (activeOption === 0) 
    //     {
    //         return;
    //     }
    //     this.setState({ activeOption: activeOption - 1, filteredOptions: [] });
    // } 
    // else {
    //   const { AutocompleteOnChange } = this.props;
    //   const { options, PrefixMinLenght } = this.props; 
    //   const userInput = e.currentTarget.value;
    //   if(userInput.length >= PrefixMinLenght) {
    //     const filteredOptions = userInput===''?options:options.filter(
    //       (option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    //       );
    //     this.setState({
    //         activeOption: 0,
    //         filteredOptions: filteredOptions,
    //         showOptions: true,
    //         userInput:e.currentTarget.value
    //     });
    //     if(AutocompleteOnChange){ AutocompleteOnChange(e.currentTarget.value)  }
    //   }
    //   else {
    //     this.setState({
    //       activeOption: 0,
    //       filteredOptions: [],
    //       showOptions: false,
    //       userInput: e.currentTarget.value
    //     });
    //   }
    // }
  };

  onClick = (e: any, i: any) => {
    //debugger    
    const { options, onValueSelect } = this.props;
    const userInput = e.currentTarget.innerText;
    // const filteredOptions = options.filter(
    //   //(option) => String(option.value).startsWith(e.currentTarget.innerText.toLowerCase())
    //     (option) => option.value.toLowerCase().indexOf(e.currentTarget.innerText.toLowerCase()) > -1
    // );
    const filteredOptions = userInput === '' ? options : options.filter(
      (option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    const filteredOptions1 = filteredOptions.filter(function (el: any) {
      return el.key === i
    });
    this.setState({
      activeOption: 0,
      filteredOptions: filteredOptions1,
      showOptions: false,
      userInput: e.currentTarget.innerText
    });

    if (onValueSelect) { onValueSelect(filteredOptions1) }
  };

  inputOnclick = (e: any) => {

    const { AutocompleteOnChange } = this.props;
    const { options, PrefixMinLenght } = this.props;
    const userInput = e.currentTarget.value;
    if (userInput.length >= PrefixMinLenght) {
      const filteredOptions = userInput === '' ? options : options.filter(
        (option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      this.setState({
        activeOption: 0,
        filteredOptions: filteredOptions,
        showOptions: true,
        userInput: e.currentTarget.value
      });
      if (AutocompleteOnChange) { AutocompleteOnChange(e.currentTarget.value) }
    }
    else {
      this.setState({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.value
      });
    }
  }
  onFocus = (e: any) => {
    e.target.select()
    ////debugger
    const { AutocompleteOnChange } = this.props;
    const { options, PrefixMinLenght } = this.props;
    const userInput = e.currentTarget.value;
    if (userInput.length >= PrefixMinLenght) {
      const filteredOptions = userInput === '' ? options : options.filter(
        (option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      this.setState({
        activeOption: 0,
        filteredOptions: filteredOptions,
        showOptions: true,
        // userInput:e.currentTarget.value
      });
      if (AutocompleteOnChange) { AutocompleteOnChange(e.currentTarget.value) }
    }
    else {
      this.setState({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.value
      });
    }
  }
  onMouseClick = (e: any) => {
    ////debugger
    const { AutocompleteOnChange } = this.props;
    const { options, PrefixMinLenght } = this.props;
    const userInput = e.currentTarget.value;
    if (userInput.length >= PrefixMinLenght) {
      const filteredOptions = userInput === '' ? options : options.filter(
        (option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      this.setState({
        activeOption: 0,
        filteredOptions: filteredOptions,
        showOptions: false,
        userInput: e.currentTarget.value
      });
      if (AutocompleteOnChange) { AutocompleteOnChange(e.currentTarget.value) }
    }
    else {
      this.setState({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.value
      });
    }
  }

  onMouseMove = (e: any) => {
    ////debugger
    this.setState({
      showOptions: false,
      filteredOptions: []
    });
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // componentWillReceiveProps()
  // {
  //   ////console.log("componentWillReceiveProps")
  //   this.setState({
  //     userInput:this.props.defaultValue
  // });
  // this.forceUpdate();

  // }
  componentDidUpdate(prevProps: any) {
    if (this.props.options.length !== prevProps.options.length) {
      // console.log("hiiii")
      this.setState({
        //filteredOptions:[],
        // showOptions: false
      })
    }
  }
  render() {
    ////debugger
    const { onChange, onKeyDown, onClick, onMouseMove, onFocus, state: { activeOption, showOptions, userInput, placeholder } } = this;
    let filteredOptions = this.state.filteredOptions;
    const { PrefixMinLenght } = this.props;

    if (filteredOptions.length === 0 && this.props.options.length !== 0) {
      filteredOptions = this.props.options;
    }

    let optionList;
    if (showOptions) {
      if (filteredOptions.length) {
        optionList = (
          <ul className="options" id='scrollDemo' >
            {filteredOptions.map((optionName, index) => {
              let className;
              if (index === activeOption) {
                className = 'option-active';
              } else {
                className = 'option-default';
              }
              return (
                <li className={className} key={optionName.key} onClick={(e) => onClick(e, optionName.key)} >
                  {optionName.value}
                </li>
              );
            })}
          </ul>
        );
      }
    }

    return (
      <React.Fragment>
        <div className="divFlex" onMouseLeave={onMouseMove}>
          {PrefixMinLenght === 3 ?
            <>
              <Tooltip title="Please enter min. 3 char" placement='top'>
                <input
                  required
                  title=""
                  type="text"
                  // onMouseLeave={onMouseMove}
                  className="search-box"
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  onClick={this.inputOnclick}
                  onMouseDown={this.onMouseClick}
                  value={userInput}
                  onFocus={this.onFocus}
                  // onFocus={(e) => }
                  disabled={this.props.disabled}
                />
                <label htmlFor="firstName" >{placeholder}</label>
                {optionList}
                <label>{placeholder}</label>

              </Tooltip>
            </>
            :
            <>
              <input
                required
                title=""
                type="text"
                // onMouseLeave={onMouseMove}
                className="search-box"
                onChange={onChange}
                onKeyDown={onKeyDown}
                //onClick={this.inputOnclick}
                //onMouseDown={this.onMouseClick}
                value={userInput}
                onFocus={(e) => onFocus(e)}
                // onFocus={(e) => e.target.select()}
                disabled={this.props.disabled}
              />
              {/* <label htmlFor="firstName" >{placeholder}</label> */}
              {optionList}
              <label>{placeholder}</label>
            </>
          }
        </div>
      </React.Fragment>
    );
  }
}
export default Autocomplete;
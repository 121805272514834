import ReactDOM from 'react-dom';
import './index.css';
import {ApComponent} from './App';
import reportWebVitals from './reportWebVitals';
import { configure } from 'mobx';
import {BrowserRouter as Router} from 'react-router-dom';
import 'antd/dist/antd.css';
import'./i18next';

configure({
  enforceActions: "never",
})

ReactDOM.render(
  
    <Router>
      <ApComponent/>
    </Router>
  ,
  document.getElementById('root')
);

reportWebVitals();

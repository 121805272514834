import { action, makeObservable, observable } from "mobx";
import { LoadDashBoardServiceReport } from "./DashboardService.service";
import { LoadDashBoardServiceEntityObj } from './DashboardUtils'

export class LoadDashBoardStore extends LoadDashBoardServiceReport {
    constructor() {
        super();
        makeObservable(this);
    }
    @observable
    public LoadDashBoardReturns: any[] = [];
    @observable
    public LoadDashBoardOBD: any[] = [];
    @observable
    public LoadDashBoardIBD: any[] = [];
    @observable
    public LoadDashBoardPO_Count: any[] = [];
    @observable
    public LoadDashBoardSO_COUNT: any[] = [];
    @observable
    public LoadDashBoardPO_Count_Open: any[] = [];
    @observable
    public LoadDashBoardSO_COUNT_Open: any[] = [];
    @observable
    public LoadDashBoardCUSTOMERRETURN_COUNT: any[] = [];
    @observable
    public LoadDashBoardSUPPLIERRETURN_COUNT: any[] = [];
     @observable
    public LoadDashBoardCancelledSo_Count: any[] = [];
    @observable
    public LoadDashBoardITEM_COUNT: any[] = [];
    @observable
    public LoadDashBoardWHOccupancy: any[] = [];
    @observable
    public LoadDashBoardstock: any[] = [];
    @observable
    public LoadDashBoardINBHour: any[] = [];
    @observable
    public LoadDashBoardOBDHour: any[] = [];
    @observable
    public LoadDashBoardLeader: any[] = [];
    @observable
    public LoadDashBoardLegger: any[] = [];
    
    @action('Get LoadGeneric in page load')
    async LoadGenericrDataStore(LoadDashBoardServiceEntityObj: LoadDashBoardServiceEntityObj) {
        ////debugger
        const res: any = await this.LoadDashBoard(LoadDashBoardServiceEntityObj);
        var getData = JSON.parse(res.data.entityObject.result);
        if (res.status === 200) {
            this.LoadDashBoardPO_Count = getData.Table
            this.LoadDashBoardSO_COUNT = getData.Table1
            this.LoadDashBoardPO_Count_Open = getData.Table2;
            this.LoadDashBoardSO_COUNT_Open = getData.Table3;
            this.LoadDashBoardIBD = getData.Table4
            this.LoadDashBoardOBD = getData.Table5
            this.LoadDashBoardCUSTOMERRETURN_COUNT = getData.Table6
            this.LoadDashBoardSUPPLIERRETURN_COUNT = getData.Table7
            this.LoadDashBoardCancelledSo_Count = getData.Table8
            this.LoadDashBoardITEM_COUNT = getData.Table9
            // this.LoadDashBoardIBD = getData.Table10
            this.LoadDashBoardstock = getData.Table11
            this.LoadDashBoardWHOccupancy = getData.Table12
            this.LoadDashBoardReturns = getData.Table13
            this.LoadDashBoardINBHour = getData.Table14
            this.LoadDashBoardOBDHour = getData.Table15
            this.LoadDashBoardLeader = getData.Table16
            this.LoadDashBoardLegger = getData.Table17
        }
    }
    
}

export const LoadDashBoardStoreCreation = new LoadDashBoardStore();
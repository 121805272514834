import { END_POINT_MasterData } from '../../../Auth/Api/Endpoint';
import { Service } from '../../axiosSetup';
import {  WHByTypeEntityObject} from './../../../Modules/TPL/NewAccount/NewAccountUtils'


export class DashBoardAutoCompleteService {
    Service: Service;
    constructor() {
        this.Service = new Service();
    }
   
    // async DashBoardService (DashBoardEntityObject:DashBoardEntityObject) {
    //     ////debugger
    //     return await this.Service.autocomplete(END_POINT_MasterData,'Common/LoadWarehouseDataInDashBoard',DashBoardEntityObject)
    // }
    async DashBoardService(WHByTypeEntityObject: WHByTypeEntityObject) {
        return await this.Service.autocomplete(END_POINT_MasterData, 'Common/GetwarehousesinuserCreation', WHByTypeEntityObject)
    }
}


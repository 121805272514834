import { Card, Col, Row } from "antd";
import { observer } from "mobx-react";
import { Component } from "react";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Service } from "../../../common/axiosSetup";
import { DashBoardAutoCompleteService } from "../../../common/Services/DashBoardAutoComplete/DashBoardAutoCompleteService";
import { IKeyValueData } from "../../mInventory/FastMovingLocsTransfer/FastMovingLocsTransferUtils";
import { LoginStoreCreation } from "../Login/LoginStore.store";
import { UserInfoEntity } from "../Login/LoginUtils";
import { LoadDashBoardStoreCreation } from "./DashboardStore.store";
import { DashBoardEntityObject, LoadDashBoardServiceEntityObj, } from "./DashboardUtils";
interface ITariffCreation {
  dropdownData: any;
  WarehouseID: number;
  WHCode: string;
  warehouse: string;
  activeIndex: number;
  StockactiveIndex: number;
  GRNDone: number,
  Name: string,
  PalletPutAway: number,
  PalletsReceived: number,
  ShipmentReceived: number,
}
@observer
class HourlyDashBoard extends Component<any, ITariffCreation>{
  LoadDashBoardOBDHour: { name: string; OrderReleased: number; PickedQty: number; PackedQty: number; DispatchQty: number; PGIComplete: number; }[];
  LoadDashBoardINBHour: { name: string; ShipmentReceived: number; PalletsReceived: number; PalletPutAway: number; GRNDone: number; }[];
  Service: Service;
  DropdownServiceobj: DashBoardAutoCompleteService;
  UserInfo: UserInfoEntity;
  // Inward: { name: string; ShipmentReceived: number; PalletsReceived: number; PalletPutAway: number; GRNDone: number; }[];
  // Outward: { name: string; OrderReleased: number; PickedQty: number; PackedQty: number; DispatchQty: number; PGIComplete: number; }[];
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.DropdownServiceobj = new DashBoardAutoCompleteService();
    const { jwtToken } = LoginStoreCreation;
    this.UserInfo = JSON.parse(jwtToken);
    this.state = {
      dropdownData: [],
      WarehouseID: 0,
      WHCode: "",
      warehouse: "",
      activeIndex: 0,
      StockactiveIndex: 0,
      GRNDone: 0,
      Name: "",
      PalletPutAway: 0,
      PalletsReceived: 0,
      ShipmentReceived: 0,
    };
    this.LoadDashBoardINBHour = [];
    this.LoadDashBoardOBDHour = [];
  }
  DashBoardAutoComplete = async (prefix: string) => {
    if (prefix === "") {
      this.setState({ dropdownData: [] });
    }
    let DashBoardEntityObject: DashBoardEntityObject = {
      AccountID: this.UserInfo.AccountID,
      prefix: "",
    };
    const res: any = await this.DropdownServiceobj.DashBoardService(
      DashBoardEntityObject
    );
    var getData = JSON.parse(res.data.entityObject.result);
    var tempData = [];
    if (res.status === 200) {
      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].WarehouseID,
          value: getData[i].warehouse,
        });
      }
    }
    this.setState({ dropdownData: tempData });
  };
  onSelectDashBOard = (data: IKeyValueData[]) => {
    this.setState({ WarehouseID: data[0].key, warehouse: data[0].value });
  };
  componentDidMount = () => {
    // let LoadDashBoardServiceEntityObj: LoadDashBoardServiceEntityObj = {
    //   WHID: this.state.WarehouseID,
    //   LoginAccountId: this.UserInfo.AccountID,
    //   LoginUserId: this.UserInfo.UserID,
    //   LoginTanentId: this.UserInfo.TenantID,
    // };
    // LoadDashBoardStoreCreation.LoadGenericrDataStore(LoadDashBoardServiceEntityObj);
  };
  render() {
    const { LoadDashBoardINBHour, LoadDashBoardOBDHour } = LoadDashBoardStoreCreation;
    return (
      <div className="App">
        <Row>
          <Col style={{ width: "100%" }}>
            <Card title="Inward" className="BarChart">
              <ResponsiveContainer width="100%" aspect={4}>
                <ComposedChart
                  width={430}
                  height={200}
                  data={LoadDashBoardINBHour}
                  margin={{
                    top: 5,
                    // right: 10,
                    bottom: 20,
                    // left: -20
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="GRNDone" barSize={17} fill="#22BB93" />
                  <Bar dataKey="PalletsReceived" barSize={17} fill="#55CEAE" />
                  <Bar dataKey="PalletPutAway" barSize={17} fill="#94D8C7" />
                  <Bar dataKey="ShipmentReceived" barSize={17} fill="#BFDBD4" />
                </ComposedChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <Card title="Outward" className="BarChart">
              <ResponsiveContainer width="100%" aspect={4}>
                <ComposedChart
                  width={430}
                  height={200}
                  data={LoadDashBoardOBDHour}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="OrderReleased" barSize={17} fill="#3952AA" />
                  <Bar dataKey="PickedQty" barSize={17} fill="#5669AB" />
                  <Bar dataKey="PackedQty" barSize={17} fill="#7D8AB8" />
                  <Bar dataKey="DispatchQty" barSize={17} fill="#A8AFC8" />
                  <Bar dataKey="PGIComplete" barSize={17} fill="#D0D5E5" />
                </ComposedChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default HourlyDashBoard;
import React, { Component, MouseEventHandler } from "react";
import "./App.css";
import HelpDesk from "./Modules/General/Dashboard/HelpDesk";
import dark from "./Assets/Images/dark_after.svg";
import light from "./Assets/Images/dark_before.svg";
import Breadcrumbs from "./common/BreadCrumb/BreadCrumb";
import { Button, Col, Dropdown, Menu, Modal, Row, Tooltip } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  BgColorsOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  GlobalOutlined,
  SearchOutlined,
  SettingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined
} from "@ant-design/icons";
import { LoginStoreCreation } from "./Modules/General/Login/LoginStore.store";
import { Link } from "react-router-dom";
import { LogInOutService } from "./Modules/General/Login/LogAudit";
import ChangePassword from "./Modules/General/Login/ChangePassword";
import Login from "./Modules/General/Login/Login";
import ForgetPassword from "./Modules/General/Login/ForgetPassword";
import i18next from "i18next";
import { reactLocalStorage } from "reactjs-localstorage";
import { UserInfoEntity } from "./Modules/General/Login/LoginUtils";
import { observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

export interface IRouterData {
  id: string;
  path: string;
  fileLocation: string;
  name: string;
  isActive: boolean;
  params?: any;
  breadcrumbPath?: string;
}


interface IState {
  router: IRouterData[];
  Storage: any;
  username: string;
  password: string;
  flag: boolean;
  ChangePassword: boolean;
  collapsed: boolean;
  dark: boolean;
  color: any,
  langInput: string,
  LanguagesList: any,
  LanguagesListTemp: any,
  visible: boolean
  fullScreen:boolean
}
@observer
class AppHeader extends Component<any, IState> {
  LoginEntityObject: any = [];
  LoginData: any;
  ForgetPasswordFlag: boolean;
  UserInfo: UserInfoEntity;
  handleOk: () => void;
  handleCancel: MouseEventHandler<HTMLElement> | undefined;
  constructor(props: any) {
    super(props);
    const { ForgetPasswordFlag } = LoginStoreCreation;
    this.ForgetPasswordFlag = ForgetPasswordFlag;
    const { jwtToken } = LoginStoreCreation;
    this.UserInfo = JSON.parse(jwtToken);
    this.state = {
      collapsed: false,
      router: [],
      username: "",
      password: "",
      Storage: [],
      flag: true,
      ChangePassword: false,
      dark: false,
      color: "",
      langInput: "",
      LanguagesList: [],
      LanguagesListTemp: [],
      visible: false,
      fullScreen:false
    };
    this.handleOk = () => {
      this.setState({ visible: false,langInput: "" ,LanguagesList: this.state.LanguagesList, LanguagesListTemp: this.state.LanguagesList});
    }
    this.handleCancel = () => {
      this.setState({ visible: false,langInput: "" ,LanguagesList: this.state.LanguagesList, LanguagesListTemp: this.state.LanguagesList});
      
    }
  }
  toggleCollapsed = () => {
    // const { collapsed } = LoginStoreCreation;
    var element: any = document.querySelector(".mastermenu");
    element.classList.toggle("trigger");
    this.setState({ collapsed: !this.state.collapsed });
    LoginStoreCreation.MenuToggleStore(this.state.collapsed);
  };
  toggledarkmode = () => {
    const { DarkMode } = LoginStoreCreation;
    if (DarkMode === true) {
      var value1: any = false;
      localStorage.setItem("dark", value1);
      document.documentElement.setAttribute("dark-theme", "false");
      LoginStoreCreation.DarkModeStore(false);
    } else {
      var value2: any = true;
      localStorage.setItem("dark", value2);
      document.documentElement.setAttribute("dark-theme", "true");
      LoginStoreCreation.DarkModeStore(true);
    }
  };
  logout = async () => {
    await LogInOutService(2);
    // setTimeout(() => {
    window.localStorage.clear();
    window.location.href = "/";
    // }, 1000);
  };
  colorChange(e: any) {
    var color_ = e.target.value;
    localStorage.setItem("color", color_);
    this.setState({ color: color_ })
    document.documentElement.style.setProperty("--basic", color_);
    document.documentElement.style.setProperty("--li-active", color_);
    document.documentElement.style.setProperty("--theadbg", color_);
    document.documentElement.style.setProperty("--panel_header", color_);
    document.documentElement.style.setProperty("--menu", color_);
    document.documentElement.style.setProperty("--btn-clr", color_);
  }
  componentDidMount = async () => {
    let color_ = localStorage.getItem("color");
    let dark: any = localStorage.getItem("dark");
    this.setState({ color: color_ === null ? "#4251ab" : color_ })
    if (color_ === "") {
      document.documentElement.style.setProperty("--basic", color_);
      document.documentElement.style.setProperty("--li-active", color_);
      document.documentElement.style.setProperty("--theadbg", color_);
      document.documentElement.style.setProperty("--panel_header", color_);
      document.documentElement.style.setProperty("--menu", color_);
      document.documentElement.style.setProperty("--btn-clr", color_);
    } else {
      document.documentElement.style.setProperty("--basic", color_);
      document.documentElement.style.setProperty("--li-active", color_);
      document.documentElement.style.setProperty("--theadbg", color_);
      document.documentElement.style.setProperty("--panel_header", color_);
      document.documentElement.style.setProperty("--menu", color_);
      document.documentElement.style.setProperty("--btn-clr", color_);
    }
    if (dark === true) {
      document.documentElement.setAttribute("dark-theme", dark);
    } else {
      document.documentElement.setAttribute("dark-theme", dark);
    }
    if (this.ForgetPasswordFlag === false) {
      LoginStoreCreation.LoadLoginStoreFromLocalStorage();
    }
    var URL = window.location.pathname;
    if (URL === "/changepassword") {
      //var URL1 = window.location.href;
      //var myArray = URL1.split('login?');
      this.setState({ ChangePassword: true });
    }
    const Languages1 = JSON.parse(reactLocalStorage.get('LangData'))
    this.setState({ LanguagesList: Languages1, LanguagesListTemp: Languages1 })
  };
  ChangeLanguage(lang: any) {
    i18next.changeLanguage(lang)
    var langugeEntity = {
      languageCode: lang,
      SSOUserID: this.UserInfo.SSOUserID
    }
    LoginStoreCreation.LoadGenericrDataStoreByLang(langugeEntity)
    setTimeout(() => {
      reactLocalStorage.set('lang', lang)
      window.location.reload();
    }, 1000)

  }
  filterNames = async (e: any) => {
    const search = e.target.value.toLowerCase()
    var Languages1: any = []
    Languages1 = this.state.LanguagesList
    const filterLanguages = Languages1.filter((value: any) => value.language.toLowerCase().includes(search))
    if (!filterLanguages.length) {
      this.setState({ langInput: search, LanguagesListTemp: [] })
    }
    else {
      this.setState({ langInput: search, LanguagesListTemp: filterLanguages })
    }

  }
  showModal() {
    this.setState({
      visible: true
    })
  }

  openFullscreen() {
    this.setState({ fullScreen: true })
    var elem: any = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      // elem = elem.document.body; //To break out of frame in IE
      elem.msRequestFullscreen();
    }
    return
  }

  closeFullscreen() {
    this.setState({ fullScreen: false })
    var elem: any = document.documentElement;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) {
      elem.msExitFullscreen();
    }
    return
  }

  render() {
    const lang: any = reactLocalStorage.get('lang') === undefined || reactLocalStorage.get('lang') === null || reactLocalStorage.get('lang') === "" ? 'en-us' : reactLocalStorage.get('lang');
    const { jwtToken, ForgetPasswordFlag, DarkMode, Languages, SubscriptionAlert } = LoginStoreCreation;
    const { t } = this.props;
    var FirstName = "";
    var LastName = "";
    if (jwtToken !== "Invalid" && jwtToken !== undefined) {
      const LoginData = jwtToken !== "" ? JSON.parse(jwtToken) : "";
      FirstName = LoginData.FirstName;
      LastName = LoginData.LastName;
    }
    const menu = (
      <Menu>
        <Menu.Item key="1" icon={<SettingOutlined />} style={{ color: "white" }}>
          <Link to={`${`/Profile`}/`}>{this.props.t('AppHead.Profile_Settings')}</Link>
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<BgColorsOutlined style={{ fontSize: "17px" }} />}
        >
          {this.props.t('AppHead.Edit_docs_color')}
        </Menu.Item>
        <Menu.Item
          onClick={() => this.logout()}
          key="2"
          icon={<LogoutOutlined />}
        >
          {this.props.t('AppHead.Logout')}
        </Menu.Item>
        <input
          type="color"
          id="sideNav-bg"
          value={this.state.color}
          onChange={(e) => this.colorChange(e)}
          style={{ width: "100%" }}
        />
        <span className="arrow"></span>
      </Menu>
    );
    const TempData: any = Languages;
    const langmenus: any = TempData !== undefined ? TempData : [];
    // const langmenu = (
    //   <Menu className="LangMenu">
    //     <input className="lang-search" type="text" onChange={(e) => this.filterNames(e)} value={this.state.langInput} />
    //     {this.state.LanguagesListTemp.map((value: any) => {
    //       return <li key={value} className="LangMenuli" onClick={() => this.ChangeLanguage(value.languageCode)} >
    //         {value.language} &nbsp;&nbsp;
    //         {/* {value.languageCode === lang ? <span className="tick-mark">&#10003;</span> : ""} */}
    //         {value.languageCode === lang ? <p className="Notify"></p> : ""}
    //       </li>;
    //     })}

    //     {this.state.LanguagesListTemp.length ?
    //       <></> : <li className="LangMenuli" >
    //         Nodata
    //       </li>
    //     }

    //   </Menu>
    // )
    if (jwtToken === "" || jwtToken === "Invalid" || jwtToken === undefined || jwtToken === null) {
      if (this.state.ChangePassword === true) {
        return <div>{<ChangePassword />}</div>;
      } else if (ForgetPasswordFlag === false) {
        return <div>{<Login />}</div>;
      } else if (ForgetPasswordFlag === true) {
        return <div>{<ForgetPassword />}</div>;
      }
    } else if (window.location.pathname === "/login") {
      window.location.href = "/";
    } else {
      return (
        <>
          <div className="head">
            <div className="Sub-head">
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggleCollapsed,
                }
              )}
              <Breadcrumbs />
            </div>
            <div style={{ width: '100%' }}>
              {
                SubscriptionAlert === "" || SubscriptionAlert.length === 0 ? <></> :
                  // <div className="early">
                  <article>
                    <p className="example-right" dangerouslySetInnerHTML={{ __html: SubscriptionAlert }}></p>
                  </article>
                // </div>
              }
            </div>
            <div className="Sub-head">
              <div className="darkicon">
                <span onClick={this.toggledarkmode} className="trigger1">
                  {DarkMode ?
                    <Tooltip placement="top" title="Light Mode">
                      <Button
                        className="ant-btn ant-btn-default ant-btn-icon-only ant-dropdown-trigger"
                        style={{ right: 0 }}
                      >
                        <img src={dark} alt="dark" />
                      </Button>
                    </Tooltip>
                    :
                    <Tooltip placement="top" title="Dark Mode">
                      <Button
                        className="ant-btn ant-btn-default ant-btn-icon-only ant-dropdown-trigger"
                        style={{ right: 0 }}
                      >
                        <img src={light} alt="light" />
                      </Button>
                    </Tooltip>}
                </span>
                <Button
                  className="ant-btn ant-btn-default ant-btn-icon-only ant-dropdown-trigger"
                  style={{ right: 10 }}
                >
                  {this.state.fullScreen === true ? <FullscreenExitOutlined style={{ color: "var(--text)" }} onClick={() => this.closeFullscreen()} /> : <FullscreenOutlined style={{ color: "var(--text)" }} onClick={() => this.openFullscreen()} />}
                </Button>
              </div>
              <div className="NavBar" style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                <Dropdown.Button
                  overlay={menu}
                  placement="bottomCenter"
                  icon={<UserOutlined style={{ height: 18, width: 18, border: '1px solid var(--text)', borderRadius: '50%', padding: '1px', fontSize:'12px'}}/>}
                >
                  {FirstName} &nbsp;{LastName}
                </Dropdown.Button>
                <Button onClick={() => this.showModal()} className='ant-btn ant-btn-default ant-btn-icon-only ant-dropdown-trigger GlobalOutlinedButton'>
                  {<GlobalOutlined className='GlobalOutlinedColor' />}
                </Button>
                <Button
                  className="ant-btn ant-btn-default ant-btn-icon-only ant-dropdown-trigger"
                  style={{ right: 0 }}
                >
                  {<HelpDesk />}
                </Button>
              </div>
            </div>
          </div>
          <Modal bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
            title={this.props.t('AppHead.Choose_a_language')}
            visible={this.state.visible}
            className="Multilang_Countries_Modal"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <></>
            ]}
          >
            <Row gutter={16} style={{ display: "flex", justifyContent: 'center' }}>
              <Col span={12}>
                <div className="divFlex lang-search-div">
                  <input className="lang-search" placeholder="Search Language" type="text" onChange={(e) => this.filterNames(e)} value={this.state.langInput} />
                  <SearchOutlined className="Icon_Align" style={{ padding: '1px 14px' }} />
                </div>
              </Col>
            </Row>
            <Row>
              {this.state.LanguagesListTemp.map((value: any,index:number) => {
                return <Col key={index} span={10} className='Multilang_Countries' onClick={() => this.ChangeLanguage(value.languageCode)}>
                  {value.language} &nbsp;&nbsp;{value.languageCode === lang ? <p className="Notify"></p> : ""}
                </Col>
              })}
            </Row>
          </Modal>
        </>
      );
    }
  }
}

export default withTranslation()(AppHeader);
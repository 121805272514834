import { loadCaptchaEnginge, LoadCanvasTemplate,  validateCaptcha } from 'react-simple-captcha'
import { Component } from 'react'

export default class LoginCaptcha extends Component {
  componentDidMount() {
    loadCaptchaEnginge(6); // Load captcha engine with 6 characters
    // Reload captcha engine every 2 minutes
    this.interval = setInterval(() => {
      loadCaptchaEnginge(6);
    }, 30000); // 30 sec in milliseconds
  }
  componentWillUnmount() {
    clearInterval(this.interval); // Clear the interval when the component is unmounted
  }
  capchavalidation() {
    let user_captcha = document.getElementById("user_captcha_input").value;
    if (validateCaptcha(user_captcha, false) === true) {
      loadCaptchaEnginge(6);
      document.getElementById("user_captcha_input").value = "";
      return true
    }
    else {
      document.getElementById("user_captcha_input").value = "";
      return false;
    }
  }
  // componentDidMount() {
  //   ////debugger
  //   loadCaptchaEnginge(6);
  // }
  render() {
    return (
      <div style={{display:'flex'}}>
        <LoadCanvasTemplate />
        {/* <LoadCanvasTemplateNoReload /> */}
        <div className="divFlex">
          <input type="text" required
            autoComplete="off"
            id="user_captcha_input"
            name="user_captcha_input"
            // onChange={this.handleChange}
            // defaultValue={AccountData.ZohoAccountId}
          /><label>Enter Captcha</label>
        </div>
        {/* <input placeholder="Enter Captcha"
          id="user_captcha_input"
          name="user_captcha_input"
          type="text"
        //onChange={this.handelchange} 
        /> */}
      </div>
    )
  }
}


import axios from "axios";
import { LoginStoreCreation } from "./LoginStore.store";
import { UserInfoEntity } from "./LoginUtils";

export async function  LogInOutService(INOUT:number) {
    //debugger
    const { jwtToken } = LoginStoreCreation;
    const UserInfo:UserInfoEntity = JSON.parse(jwtToken ? jwtToken : "");
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data.IPv4);
    var IP = res.data.IPv4
    //setIP(res.data.IPv4)
    var LogInOutEntity = {
        "UserID":UserInfo.UserID,
        "INOUT":INOUT,
        "IPAddress":IP,
        "TenantID":UserInfo.TenantID,
    }  
    LoginStoreCreation.LogInOutStore(LogInOutEntity);
};